import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import './features6.css'

const Features6 = (props) => {
  return (
    <div
      className={`features6-layout216 thq-section-padding ${props.rootClassName} `}
    >
      <div className="features6-max-width thq-flex-row thq-section-max-width">
        <div className="thq-flex-column">
          <img
            alt={props.featureImageAlt}
            src={props.featureImageSrc}
            className="thq-img-ratio-4-3 features6-image1"
          />
        </div>
        <div className="thq-flex-column features6-content">
          <div className="features6-section-title thq-flex-column">
            <h2 className="thq-heading-2">
              {props.sectionTitle ?? (
                <Fragment>
                  <span className="features6-text19">Biz Kimiz?</span>
                </Fragment>
              )}
            </h2>
            <p className="features6-text11 thq-body-large">
              {props.featureDescription ?? (
                <Fragment>
                  <span className="features6-text12">
                    <span>
                      2012 yılından bu yana, başta Ankara ve İstanbul olmak
                      üzere, Türkiye’nin farklı şehirlerinde pek çok özel ve
                      kurumsal etkinlik düzenlemekteyiz. ELB Organizasyon
                      olarak, alanında uzman şeflerimiz, deneyimli
                      eğitmenlerimiz ve profesyonel ekibimizle, müşterilerimizin
                      ihtiyaç ve beklentilerine yönelik yenilikçi çözümler
                      sunmaktayız.
                    </span>
                    <br></br>
                    <br></br>
                    <span>
                      Viski tadım etkinliklerinden, özenle planlanmış yemek
                      organizasyonlarına; şık bahçe partilerinden geniş çaplı
                      kurumsal etkinliklere kadar geniş bir hizmet yelpazesi
                      sunarak, her etkinliği benzersiz kılmakta kararlıyız.
                      Misafirlerimizin memnuniyeti, sunduğumuz hizmetin kalitesi
                      ve etkinliklerimizin başarısı için temel önceliğimizdir.
                    </span>
                    <span>
                      <span
                        dangerouslySetInnerHTML={{
                          __html: ' ',
                        }}
                      />
                    </span>
                  </span>
                </Fragment>
              )}
            </p>
          </div>
          <div className="features6-actions thq-flex-row">
            <Link
              to="/hizmetler"
              className="features6-button1 thq-button-filled"
            >
              <span className="thq-body-small">
                {props.mainAction ?? (
                  <Fragment>
                    <span className="features6-text20">
                      Hizmetleri Keşfedin
                    </span>
                  </Fragment>
                )}
              </span>
            </Link>
            <Link
              to="/iletisim"
              className="features6-button2 thq-button-outline"
            >
              <span className="thq-body-small">
                {props.secondaryAction ?? (
                  <Fragment>
                    <span className="features6-text18">İletişime Geçin</span>
                  </Fragment>
                )}
              </span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

Features6.defaultProps = {
  featureDescription: undefined,
  featureImageAlt: 'Özellikler',
  rootClassName: '',
  featureImageSrc:
    '/Logos/whatsapp%20image%202024-10-01%20at%2016.35.06-1400w.webp',
  secondaryAction: undefined,
  sectionTitle: undefined,
  mainAction: undefined,
}

Features6.propTypes = {
  featureDescription: PropTypes.element,
  featureImageAlt: PropTypes.string,
  rootClassName: PropTypes.string,
  featureImageSrc: PropTypes.string,
  secondaryAction: PropTypes.element,
  sectionTitle: PropTypes.element,
  mainAction: PropTypes.element,
}

export default Features6
