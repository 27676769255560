import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import './footer.css'

const Footer = (props) => {
  return (
    <footer
      className={`footer-container thq-section-padding ${props.rootClassName} `}
    >
      <div className="footer-max-width thq-section-max-width">
        <div className="footer-content">
          <div className="footer-newsletter">
            <img
              alt={props.logoAlt}
              src={props.logoSrc}
              className="footer-image1"
            />
            <span className="footer-content-title1 thq-body-small">Adres</span>
            <span className="footer-content1 thq-body-small">
              <span>4. Levent Konaklar Mah. Selvili Sk.</span>
              <br></br>
              <span>No. 8/B Beşiktaş / İstanbul</span>
              <br></br>
            </span>
            <span className="thq-body-small">
              <span className="footer-text14">İletişim</span>
              <br className="footer-text15"></br>
              <span>+90 536 232 6673</span>
              <br></br>
              <span>info@elborganizasyon.com</span>
              <br></br>
            </span>
            <div className="footer-social-links">
              <a
                href="https://www.instagram.com/elborganizasyon?utm_source=ig_web_button_share_sheet&amp;igsh=ZDNlZDc0MzIxNw=="
                target="_blank"
                rel="noreferrer noopener"
                className="footer-link1"
              >
                <svg
                  viewBox="0 0 877.7142857142857 1024"
                  className="footer-icon1 thq-icon-small"
                >
                  <path d="M585.143 512c0-80.571-65.714-146.286-146.286-146.286s-146.286 65.714-146.286 146.286 65.714 146.286 146.286 146.286 146.286-65.714 146.286-146.286zM664 512c0 124.571-100.571 225.143-225.143 225.143s-225.143-100.571-225.143-225.143 100.571-225.143 225.143-225.143 225.143 100.571 225.143 225.143zM725.714 277.714c0 29.143-23.429 52.571-52.571 52.571s-52.571-23.429-52.571-52.571 23.429-52.571 52.571-52.571 52.571 23.429 52.571 52.571zM438.857 152c-64 0-201.143-5.143-258.857 17.714-20 8-34.857 17.714-50.286 33.143s-25.143 30.286-33.143 50.286c-22.857 57.714-17.714 194.857-17.714 258.857s-5.143 201.143 17.714 258.857c8 20 17.714 34.857 33.143 50.286s30.286 25.143 50.286 33.143c57.714 22.857 194.857 17.714 258.857 17.714s201.143 5.143 258.857-17.714c20-8 34.857-17.714 50.286-33.143s25.143-30.286 33.143-50.286c22.857-57.714 17.714-194.857 17.714-258.857s5.143-201.143-17.714-258.857c-8-20-17.714-34.857-33.143-50.286s-30.286-25.143-50.286-33.143c-57.714-22.857-194.857-17.714-258.857-17.714zM877.714 512c0 60.571 0.571 120.571-2.857 181.143-3.429 70.286-19.429 132.571-70.857 184s-113.714 67.429-184 70.857c-60.571 3.429-120.571 2.857-181.143 2.857s-120.571 0.571-181.143-2.857c-70.286-3.429-132.571-19.429-184-70.857s-67.429-113.714-70.857-184c-3.429-60.571-2.857-120.571-2.857-181.143s-0.571-120.571 2.857-181.143c3.429-70.286 19.429-132.571 70.857-184s113.714-67.429 184-70.857c60.571-3.429 120.571-2.857 181.143-2.857s120.571-0.571 181.143 2.857c70.286 3.429 132.571 19.429 184 70.857s67.429 113.714 70.857 184c3.429 60.571 2.857 120.571 2.857 181.143z"></path>
                </svg>
              </a>
              <a
                href="http://wa.me/+905362326673"
                target="_blank"
                rel="noreferrer noopener"
                className="footer-link2"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  className="footer-icon3 thq-icon-small"
                >
                  <g fill="currentColor">
                    <path
                      d="M12 4a8 8 0 0 0-6.895 12.06l.569.718l-.697 2.359l2.32-.648l.379.243A8 8 0 1 0 12 4M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10s-4.477 10-10 10a9.96 9.96 0 0 1-5.016-1.347l-4.948 1.382l1.426-4.829l-.006-.007l-.033-.055A9.96 9.96 0 0 1 2 12"
                      clip-rule="evenodd"
                      fill-rule="evenodd"
                    ></path>
                    <path d="M16.735 13.492c-.038-.018-1.497-.736-1.756-.83a1 1 0 0 0-.34-.075c-.196 0-.362.098-.49.291c-.146.217-.587.732-.723.886c-.018.02-.042.045-.057.045c-.013 0-.239-.093-.307-.123c-1.564-.68-2.751-2.313-2.914-2.589c-.023-.04-.024-.057-.024-.057c.005-.021.058-.074.085-.101c.08-.079.166-.182.249-.283l.117-.14c.121-.14.175-.25.237-.375l.033-.066a.68.68 0 0 0-.02-.64c-.034-.069-.65-1.555-.715-1.711c-.158-.377-.366-.552-.655-.552c-.027 0 0 0-.112.005c-.137.005-.883.104-1.213.311c-.35.22-.94.924-.94 2.16c0 1.112.705 2.162 1.008 2.561l.041.06c1.161 1.695 2.608 2.951 4.074 3.537c1.412.564 2.081.63 2.461.63c.16 0 .288-.013.4-.024l.072-.007c.488-.043 1.56-.599 1.804-1.276c.192-.534.243-1.117.115-1.329c-.088-.144-.239-.216-.43-.308"></path>
                  </g>
                </svg>
              </a>
            </div>
          </div>
          <div className="footer-links">
            <div className="footer-column2">
              <strong className="thq-body-large footer-column2-title">
                {props.column2Title ?? (
                  <Fragment>
                    <span className="footer-text25">Site Haritası</span>
                  </Fragment>
                )}
              </strong>
              <div className="footer-footer-links1">
                <Link to="/" className="thq-body-small">
                  {props.link6 ?? (
                    <Fragment>
                      <span className="footer-text23">Anasayfa</span>
                    </Fragment>
                  )}
                </Link>
                <Link to="/hakkimizda" className="thq-body-small">
                  {props.link7 ?? (
                    <Fragment>
                      <span className="footer-text22">Hakkımızda</span>
                    </Fragment>
                  )}
                </Link>
                <Link to="/hizmetler" className="thq-body-small">
                  {props.link8 ?? (
                    <Fragment>
                      <span className="footer-text21">Hizmetler</span>
                    </Fragment>
                  )}
                </Link>
                <Link to="/iletisim" className="thq-body-small">
                  {props.link9 ?? (
                    <Fragment>
                      <span className="footer-text26">İletişim</span>
                    </Fragment>
                  )}
                </Link>
                <Link to="/iletisim" className="thq-body-small">
                  {props.link10 ?? (
                    <Fragment>
                      <span className="footer-text20">Hemen Teklif Alın!</span>
                    </Fragment>
                  )}
                </Link>
              </div>
            </div>
            <div className="footer-column1">
              <div className="footer-footer-links2">
                <img
                  alt={props.imageAlt}
                  src={props.imageSrc}
                  className="footer-image"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="footer-credits">
          <div className="footer-row">
            <span className="thq-body-small">
              {props.copyright ?? (
                <Fragment>
                  <span className="footer-text24">
                    © 2024 ELB Organizasyon. Tüm hakları saklıdır.
                  </span>
                </Fragment>
              )}
            </span>
          </div>
        </div>
      </div>
    </footer>
  )
}

Footer.defaultProps = {
  logoSrc: '/ELB Logos/image3-200h.webp',
  link10: undefined,
  imageAlt: 'image',
  logoAlt: 'ELB Organizasyon Logo',
  link8: undefined,
  link7: undefined,
  rootClassName: '',
  imageSrc: '/Logos/ssllogo-200h.webp',
  link6: undefined,
  copyright: undefined,
  column2Title: undefined,
  link9: undefined,
}

Footer.propTypes = {
  logoSrc: PropTypes.string,
  link10: PropTypes.element,
  imageAlt: PropTypes.string,
  logoAlt: PropTypes.string,
  link8: PropTypes.element,
  link7: PropTypes.element,
  rootClassName: PropTypes.string,
  imageSrc: PropTypes.string,
  link6: PropTypes.element,
  copyright: PropTypes.element,
  column2Title: PropTypes.element,
  link9: PropTypes.element,
}

export default Footer
