import React, { Fragment } from 'react'

import { Helmet } from 'react-helmet'

import Navbar from '../components/navbar'
import Hero12 from '../components/hero12'
import LogosReferences from '../components/logos-references'
import LogosPartners from '../components/logos-partners'
import Footer from '../components/footer'
import './anasayfa.css'

const Anasayfa = (props) => {
  return (
    <div className="anasayfa-container">
      <Helmet>
        <title>ELB Organizasyon</title>
        <meta
          name="description"
          content="ELB Organizasyon, İstanbul 4. Levent ve Beşiktaş’ta özel ve kurumsal etkinlikler sunan, catering ve tadım hizmetleriyle öne çıkan bir organizasyon şirketidir"
        />
        <meta property="og:title" content="ELB Organizasyon" />
        <meta
          property="og:description"
          content="ELB Organizasyon, İstanbul 4. Levent ve Beşiktaş’ta özel ve kurumsal etkinlikler sunan, catering ve tadım hizmetleriyle öne çıkan bir organizasyon şirketidir"
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/d8f84395-ef7e-45f7-bd5b-7c31cdc2ceb7/90edf666-b00f-4372-9642-7abb01325de6?org_if_sml=1&amp;q=80&amp;force_format=original"
        />
        <meta name="robots" content="noindex" />
      </Helmet>
      <Navbar
        link1={
          <Fragment>
            <span className="anasayfa-text10">ANASAYFA</span>
          </Fragment>
        }
        link2={
          <Fragment>
            <span className="anasayfa-text11">HAKKIMIZDA</span>
          </Fragment>
        }
        link3={
          <Fragment>
            <span className="anasayfa-text12">HİZMETLER</span>
          </Fragment>
        }
        link4={
          <Fragment>
            <span className="anasayfa-text13">İLETİŞİM</span>
          </Fragment>
        }
        action1={
          <Fragment>
            <span className="anasayfa-text14">Hemen Arayın</span>
          </Fragment>
        }
        action2={
          <Fragment>
            <span className="anasayfa-text15">Instagram</span>
          </Fragment>
        }
      ></Navbar>
      <Hero12
        action1={
          <Fragment>
            <span className="anasayfa-text16">Hizmetleri Keşfedin</span>
          </Fragment>
        }
        action2={
          <Fragment>
            <span className="anasayfa-text17">Teklif Al</span>
          </Fragment>
        }
        content1={
          <Fragment>
            <span className="anasayfa-text18">
              ELB Organizasyon olarak, etkinliklerinizi unutulmaz kılmak için
              yanınızdayız!
            </span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <span className="anasayfa-text19">
              <span>
                SİZ HAYAL EDİN,
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
              <br></br>
              <span>BİZ GERÇEKLEŞTİRELİM</span>
            </span>
          </Fragment>
        }
      ></Hero12>
      <LogosReferences
        heading1={
          <Fragment>
            <span className="anasayfa-text23">Referanslarımız</span>
          </Fragment>
        }
        rootClassName="logos-referencesroot-class-name"
      ></LogosReferences>
      <LogosPartners
        heading1={
          <Fragment>
            <span className="anasayfa-text24">Partnerlerimiz</span>
          </Fragment>
        }
      ></LogosPartners>
      <Footer
        link6={
          <Fragment>
            <span className="anasayfa-text25">Anasayfa</span>
          </Fragment>
        }
        link7={
          <Fragment>
            <span className="anasayfa-text26">Hakkımızda</span>
          </Fragment>
        }
        link8={
          <Fragment>
            <span className="anasayfa-text27">Hizmetler</span>
          </Fragment>
        }
        link9={
          <Fragment>
            <span className="anasayfa-text28">İletişim</span>
          </Fragment>
        }
        link10={
          <Fragment>
            <span className="anasayfa-text29">Hemen Teklif Alın!</span>
          </Fragment>
        }
        copyright={
          <Fragment>
            <span className="anasayfa-text30">
              © 2024 ELB Organizasyon. Tüm hakları saklıdır.
            </span>
          </Fragment>
        }
        column2Title={
          <Fragment>
            <span className="anasayfa-text31">Site Haritası</span>
          </Fragment>
        }
        rootClassName="footerroot-class-name2"
      ></Footer>
    </div>
  )
}

export default Anasayfa
