import React from 'react'

import PropTypes from 'prop-types'

import './gallery9.css'

const Gallery9 = (props) => {
  return (
    <div className="gallery9-gallery3 thq-section-padding">
      <div className="gallery9-max-width thq-section-max-width">
        <div className="gallery9-section-title"></div>
        <div className="gallery9-container1">
          <div className="gallery9-content">
            <div
              data-thq="slider"
              data-navigation="true"
              data-pagination="true"
              className="gallery9-slider swiper"
            >
              <div
                data-thq="slider-wrapper"
                className="gallery9-slider-wrapper swiper-wrapper"
              >
                <div
                  data-thq="slider-slide"
                  className="gallery9-slider-slide1 swiper-slide"
                >
                  <div className="gallery9-container2">
                    <img
                      alt={props.image1Alt}
                      src={props.image1Src}
                      className="gallery9-image1 thq-img-ratio-4-3"
                    />
                  </div>
                  <div className="gallery9-container3">
                    <img
                      alt={props.image2Alt}
                      src={props.image2Src}
                      className="gallery9-image2 thq-img-ratio-4-3"
                    />
                  </div>
                </div>
                <div
                  data-thq="slider-slide"
                  className="gallery9-slider-slide2 swiper-slide"
                >
                  <div className="gallery9-container4">
                    <img
                      alt={props.image3Alt}
                      src={props.image3Src}
                      className="gallery9-image3 thq-img-ratio-4-3"
                    />
                  </div>
                  <div className="gallery9-container5">
                    <img
                      alt={props.image4Alt}
                      src={props.image4Src}
                      className="gallery9-image4 thq-img-ratio-4-3"
                    />
                  </div>
                </div>
                <div
                  data-thq="slider-slide"
                  className="gallery9-slider-slide3 swiper-slide"
                >
                  <div className="gallery9-container6">
                    <img
                      alt={props.image5Alt}
                      src={props.image5Src}
                      className="gallery9-image5 thq-img-ratio-4-3"
                    />
                  </div>
                  <div className="gallery9-container7">
                    <img
                      alt={props.image6Alt}
                      src={props.image6Src}
                      className="gallery9-image6 thq-img-ratio-4-3"
                    />
                  </div>
                </div>
              </div>
              <div
                data-thq="slider-button-prev"
                className="swiper-button-prev"
              ></div>
              <div
                data-thq="slider-button-next"
                className="swiper-button-next"
              ></div>
              <div
                data-thq="slider-pagination"
                className="gallery9-slider-pagination swiper-pagination swiper-pagination-bullets swiper-pagination-horizontal"
              >
                <div
                  data-thq="slider-pagination-bullet"
                  className="swiper-pagination-bullet swiper-pagination-bullet-active"
                ></div>
                <div
                  data-thq="slider-pagination-bullet"
                  className="swiper-pagination-bullet"
                ></div>
                <div
                  data-thq="slider-pagination-bullet"
                  className="swiper-pagination-bullet"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Gallery9.defaultProps = {
  image5Alt: 'Creative stage setup for a product launch',
  image2Alt: 'Beautiful floral arrangements for a wedding',
  image2Src: '/orgduzen/dzenleme%207-1400w.webp',
  image1Alt: 'Event setup with elegant decorations',
  image3Alt: 'Stunning lighting design at a corporate event',
  image3Src: '/orgduzen/dzenleme%205-1400w.webp',
  image6Alt: 'Chic lounge area for a private party',
  image6Src: '/orgduzen/letter%20with%20elb%20org.-1400w.webp',
  image4Alt: 'Exquisite table setting for a gala dinner',
  image1Src: '/orgduzen/couple%20photo%20capital-1400w.webp',
  image5Src: '/orgduzen/dzenleme%206-1400w.webp',
  image4Src: '/orgduzen/img_1453-1400w.webp',
}

Gallery9.propTypes = {
  image5Alt: PropTypes.string,
  image2Alt: PropTypes.string,
  image2Src: PropTypes.string,
  image1Alt: PropTypes.string,
  image3Alt: PropTypes.string,
  image3Src: PropTypes.string,
  image6Alt: PropTypes.string,
  image6Src: PropTypes.string,
  image4Alt: PropTypes.string,
  image1Src: PropTypes.string,
  image5Src: PropTypes.string,
  image4Src: PropTypes.string,
}

export default Gallery9
