import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './content-list12.css'

const ContentList12 = (props) => {
  return (
    <div className="content-list12-container thq-section-padding">
      <div className="content-list12-max-width thq-section-max-width">
        <div className="content-list12-content thq-flex-column">
          <ul className="content-list12-ul thq-flex-column">
            <li className="thq-flex-column list-item">
              <p className="thq-body-small">
                {props.content1 ?? (
                  <Fragment>
                    <span className="content-list12-text1">
                      ELB Organizasyon is a leading event planning company
                      dedicated to creating unforgettable experiences for all
                      types of events.
                    </span>
                  </Fragment>
                )}
              </p>
            </li>
            <li className="list-item">
              <h3 className="thq-heading-3">
                {props.heading2 ?? (
                  <Fragment>
                    <span className="content-list12-text3">Our Services</span>
                  </Fragment>
                )}
              </h3>
              <p className="thq-body-small">
                {props.content2 ?? (
                  <Fragment>
                    <span className="content-list12-text2">
                      We offer a wide range of services including event
                      planning, venue selection, catering, entertainment,
                      decorations, and more to meet all your event needs.
                    </span>
                  </Fragment>
                )}
              </p>
            </li>
            <li className="list-item"></li>
            <li className="list-item"></li>
            <li className="list-item"></li>
            <li className="list-item"></li>
          </ul>
        </div>
      </div>
    </div>
  )
}

ContentList12.defaultProps = {
  content1: undefined,
  content2: undefined,
  heading2: undefined,
}

ContentList12.propTypes = {
  content1: PropTypes.element,
  content2: PropTypes.element,
  heading2: PropTypes.element,
}

export default ContentList12
