import React, { Fragment } from 'react'

import Script from 'dangerous-html/react'
import PropTypes from 'prop-types'

import './elb-logos.css'

const ElbLogos = (props) => {
  return (
    <div
      className={`elb-logos-container1 thq-section-padding ${props.rootClassName} `}
    >
      <div className="elb-logos-max-width thq-section-max-width">
        <h2 className="thq-heading-2">
          {props.heading1 ?? (
            <Fragment>
              <span className="elb-logos-text2">ELB GRUP</span>
            </Fragment>
          )}
        </h2>
      </div>
      <div className="elb-logos-container2 thq-animated-group-container-horizontal thq-mask-image-horizontal">
        <div className="thq-animated-group-horizontal">
          <img
            alt={props.logo1Alt}
            src={props.logo1Src}
            className="elb-logos-logo11 thq-img-ratio-16-9"
          />
          <img
            alt={props.logo2Alt}
            src={props.logo2Src}
            className="elb-logos-logo21 thq-img-ratio-16-9"
          />
          <img
            alt={props.logo3Alt}
            src={props.logo3Src}
            className="elb-logos-logo31 thq-img-ratio-16-9"
          />
          <img
            alt={props.logo4Alt}
            src={props.logo4Src}
            className="elb-logos-logo41 thq-img-ratio-16-9"
          />
        </div>
        <div className="thq-animated-group-horizontal">
          <img
            alt={props.logo1Alt}
            src={props.logo1Src}
            className="elb-logos-logo12 thq-img-ratio-16-9"
          />
          <img
            alt={props.logo2Alt}
            src={props.logo2Src}
            className="elb-logos-logo22 thq-img-ratio-16-9"
          />
          <img
            alt={props.logo3Alt}
            src={props.logo3Src}
            className="elb-logos-logo32 thq-img-ratio-16-9"
          />
          <img
            alt={props.logo4Alt}
            src={props.logo4Src}
            className="elb-logos-logo42 thq-img-ratio-16-9"
          />
        </div>
      </div>
      <div>
        <div className="elb-logos-container6">
          <Script
            html={`<style>
  @keyframes scroll-x {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(calc(-100% - 16px));
    }
  }

  @keyframes scroll-y {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(calc(-100% - 16px));
    }
  }
</style>
`}
          ></Script>
        </div>
      </div>
    </div>
  )
}

ElbLogos.defaultProps = {
  logo2Alt: 'Event Planning logo',
  logo1Src: '/ELB Logos/image4-1500w.webp',
  logo4Alt: 'Catering logo',
  logo3Src: '/ELB Logos/image2-1500w.webp',
  rootClassName: '',
  logo1Alt: 'ELB Organizasyon logo',
  logo4Src: '/ELB Logos/image1-1500w.webp',
  logo2Src: '/ELB Logos/image3-1500w.webp',
  logo3Alt: 'Decoration Services logo',
  heading1: undefined,
}

ElbLogos.propTypes = {
  logo2Alt: PropTypes.string,
  logo1Src: PropTypes.string,
  logo4Alt: PropTypes.string,
  logo3Src: PropTypes.string,
  rootClassName: PropTypes.string,
  logo1Alt: PropTypes.string,
  logo4Src: PropTypes.string,
  logo2Src: PropTypes.string,
  logo3Alt: PropTypes.string,
  heading1: PropTypes.element,
}

export default ElbLogos
