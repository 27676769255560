import React, { Fragment } from 'react'

import { Helmet } from 'react-helmet'

import Navbar from '../components/navbar'
import Features16 from '../components/features16'
import Gallery5 from '../components/gallery5'
import LogosReferences from '../components/logos-references'
import LogosPartners from '../components/logos-partners'
import Footer from '../components/footer'
import './hizmetler.css'

const Hizmetler = (props) => {
  return (
    <div className="hizmetler-container">
      <Helmet>
        <title>ELB Organizasyon</title>
        <meta
          name="description"
          content="ELB Organizasyon, İstanbul 4. Levent ve Beşiktaş’ta özel ve kurumsal etkinlikler sunan, catering ve tadım hizmetleriyle öne çıkan bir organizasyon şirketidir"
        />
        <meta property="og:title" content="ELB Organizasyon" />
        <meta
          property="og:description"
          content="ELB Organizasyon, İstanbul 4. Levent ve Beşiktaş’ta özel ve kurumsal etkinlikler sunan, catering ve tadım hizmetleriyle öne çıkan bir organizasyon şirketidir"
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/d8f84395-ef7e-45f7-bd5b-7c31cdc2ceb7/cb81269f-5301-494a-a792-4549cb492f4c?org_if_sml=1&amp;q=80&amp;force_format=original"
        />
      </Helmet>
      <Navbar
        link1={
          <Fragment>
            <span className="hizmetler-text10">ANASAYFA</span>
          </Fragment>
        }
        link2={
          <Fragment>
            <span className="hizmetler-text11">HAKKIMIZDA</span>
          </Fragment>
        }
        link3={
          <Fragment>
            <span className="hizmetler-text12">HİZMETLER</span>
          </Fragment>
        }
        link4={
          <Fragment>
            <span className="hizmetler-text13">İLETİŞİM</span>
          </Fragment>
        }
        action1={
          <Fragment>
            <span className="hizmetler-text14">Hemen Arayın</span>
          </Fragment>
        }
        action2={
          <Fragment>
            <span className="hizmetler-text15">Instagram</span>
          </Fragment>
        }
        rootClassName="navbarroot-class-name1"
      ></Navbar>
      <Features16
        content1={
          <Fragment>
            <span className="hizmetler-text16">
              Özenle planlanmış Kurumsal Etkinlikler ve özel günlerinize değer
              katan Özel Davetler ile her anınızı özel hale getiriyoruz.
              Lezzetli ikramlar ve seçkin içeceklerden oluşan Catering ve Alkol
              Hizmetimizle, davetlerinize lezzet katıyoruz.
            </span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <span className="hizmetler-text17">
              <span>Lezzetli ve Keyifli Saatlerin Tadını Çıkarın</span>
              <br></br>
            </span>
          </Fragment>
        }
        feature1Title={
          <Fragment>
            <span className="hizmetler-text20">Catering</span>
          </Fragment>
        }
        feature2Title={
          <Fragment>
            <span className="hizmetler-text21">Tadım Etkinlikleri</span>
          </Fragment>
        }
        feature3Title={
          <Fragment>
            <span className="hizmetler-text22">Workshoplar</span>
          </Fragment>
        }
        rootClassName="features16root-class-name"
        feature1Description={
          <Fragment>
            <span className="hizmetler-text23">
              <span>
                🍽️ Lezzetin Sanatı: Her bir tabakta, ustalıkla hazırlanmış
                yemeklerimizi sunuyoruz. Geleneksel lezzetlerden modern
                yaratıcılığa uzanan geniş menümüz, her damak zevkine hitap
                ediyor.
              </span>
              <br></br>
              <br></br>
              <span>
                🌿 Kalite ve Tazelik: Yalnızca en taze ve en kaliteli
                malzemeleri kullanıyoruz. Her bir malzemenin seçimi, yemeğin
                lezzeti kadar önemli. Bu nedenle, tedarik sürecimizde dikkatli
                ve özenliyiz.
              </span>
              <br></br>
              <br></br>
              <span>
                🎉 Her Etkinlik İçin Özel: İster küçük bir toplantı, ister büyük
                bir organizasyon olsun, her etkinlik için özel olarak
                tasarlanmış menülerimizle hizmetinizdeyiz. Etkinliğinizin
                temasına ve zevkinize göre kişiselleştirilmiş menü seçenekleri
                sunuyoruz.
              </span>
              <br></br>
              <br></br>
              <span>
                🍸 Tamamlayıcı İçecek Seçenekleri: Yemeklerin yanında servis
                edilen içecekler de bir o kadar önemli. İçecek menümüz,
                yemeklerimizle mükemmel uyum sağlayacak şekilde hazırlanıyor.
              </span>
            </span>
          </Fragment>
        }
        feature2Description={
          <Fragment>
            <span className="hizmetler-text34">
              <span>
                🥃 Viski Tadımı:  Dünyanın dört bir yanından gelen seçkin
                viskilerin gizemli notalarına dalın. Aged Single Malt&apos;tan
                zengin Blend&apos;lere kadar pek çok viski çeşidini keşfetme
                fırsatı sizi bekliyor.
              </span>
              <br></br>
              <br></br>
              <span>
                🍺 Bira Tadımı:  Ale&apos;den Lager&apos;a, Pilsen&apos;den
                Stout&apos;a kadar birbirinden farklı bira çeşitlerinin lezzet
                paletini deneyimleyin. Her bir yudumda, biranın zengin tarihine
                ve çeşitliliğine tanıklık edin.
              </span>
              <br></br>
              <br></br>
              <span>
                🍷 Şarap Tadımı:  Kırmızı, beyaz ve roze... Üzümün sihirli
                dönüşümüyle oluşan bu lezzetleri keşfedin. Şarapların
                arkasındaki hikayeleri ve bu eşsiz içeceğin nasıl yapıldığını
                öğrenin.
              </span>
              <br></br>
              <span> </span>
            </span>
          </Fragment>
        }
        feature3Description={
          <Fragment>
            <span className="hizmetler-text44">
              <span>
                ✨ Kokteyl Yapımı:  Profesyonel mixologistlerin eşliğinde
                Mojito, Margarita ve Whisky Sour gibi klasik kokteylleri nasıl
                mükemmel bir şekilde hazırlayacağınızı öğrenin! Hem klasik
                tarifleri keşfedin hem de bu temel bilgilerle kendi yaratıcı
                karışımlarınızı oluşturma fırsatı yakalayın.
              </span>
              <br></br>
              <br></br>
              <span>
                🍣 Suşi Yapımı:  Deneyimli şeflerimizin rehberliğinde, taze
                malzemelerle suşi yapımının püf noktalarını öğrenin.
                Katılımcılarımız, bu eşsiz lezzeti evlerine taşıyabilmenin
                sırlarını keşfedecekler.
              </span>
              <br></br>
              <br></br>
              <span>
                🎨 Yaratıcı Etkinlikler:  Hayal gücünüzü sınırlamayın!
                Yaratıcılığınızı en üst düzeye çıkaracak atölye
                çalışmalarımızla, içsel potansiyelinizi ortaya çıkarın. Hangi
                alanda tutkuluysanız, onu keşfetmek için sizi bekliyoruz.
              </span>
            </span>
          </Fragment>
        }
      ></Features16>
      <Gallery5></Gallery5>
      <LogosReferences
        heading1={
          <Fragment>
            <span className="hizmetler-text52">Referanslarımız</span>
          </Fragment>
        }
      ></LogosReferences>
      <LogosPartners
        heading1={
          <Fragment>
            <span className="hizmetler-text53">Partnerlerimiz</span>
          </Fragment>
        }
        rootClassName="logos-partnersroot-class-name"
      ></LogosPartners>
      <Footer
        link6={
          <Fragment>
            <span className="hizmetler-text54">Anasayfa</span>
          </Fragment>
        }
        link7={
          <Fragment>
            <span className="hizmetler-text55">Hakkımızda</span>
          </Fragment>
        }
        link8={
          <Fragment>
            <span className="hizmetler-text56">Hizmetler</span>
          </Fragment>
        }
        link9={
          <Fragment>
            <span className="hizmetler-text57">İletişim</span>
          </Fragment>
        }
        link10={
          <Fragment>
            <span className="hizmetler-text58">Hemen Teklif Alın!</span>
          </Fragment>
        }
        copyright={
          <Fragment>
            <span className="hizmetler-text59">
              © 2024 ELB Organizasyon. Tüm hakları saklıdır.
            </span>
          </Fragment>
        }
        column2Title={
          <Fragment>
            <span className="hizmetler-text60">Site Haritası</span>
          </Fragment>
        }
        rootClassName="footerroot-class-name"
      ></Footer>
    </div>
  )
}

export default Hizmetler
