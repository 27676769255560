import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './features16.css'

const Features16 = (props) => {
  return (
    <div
      className={`features16-layout300 thq-section-padding ${props.rootClassName} `}
    >
      <div className="features16-max-width thq-section-max-width">
        <div className="features16-section-title">
          <div className="features16-content1">
            <h2 className="features16-text10 thq-heading-2">
              {props.heading1 ?? (
                <Fragment>
                  <span className="features16-text25">
                    <span>Lezzetli ve Keyifli Saatlerin Tadını Çıkarın</span>
                    <br></br>
                  </span>
                </Fragment>
              )}
            </h2>
            <span className="features16-text11 thq-body-large">
              {props.content1 ?? (
                <Fragment>
                  <span className="features16-text23">
                    Özenle planlanmış Kurumsal Etkinlikler ve özel günlerinize
                    değer katan Özel Davetler ile her anınızı özel hale
                    getiriyoruz. Lezzetli ikramlar ve seçkin içeceklerden oluşan
                    Catering ve Alkol Hizmetimizle, davetlerinize lezzet
                    katıyoruz.
                  </span>
                </Fragment>
              )}
            </span>
          </div>
        </div>
        <div className="features16-content2">
          <div className="features16-row thq-flex-row">
            <div className="features16-feature1">
              <img
                alt={props.feature1ImageAlt}
                src={props.feature1ImageSrc}
                className="thq-img-ratio-4-3"
              />
              <div className="features16-content3">
                <h3 className="thq-heading-3">
                  {props.feature1Title ?? (
                    <Fragment>
                      <span className="features16-text47">Catering</span>
                    </Fragment>
                  )}
                </h3>
                <span className="thq-body-small">
                  {props.feature1Description ?? (
                    <Fragment>
                      <span className="features16-text12">
                        <span>
                          🍽️ Lezzetin Sanatı: Her bir tabakta, ustalıkla
                          hazırlanmış yemeklerimizi sunuyoruz. Geleneksel
                          lezzetlerden modern yaratıcılığa uzanan geniş menümüz,
                          her damak zevkine hitap ediyor.
                        </span>
                        <br></br>
                        <br></br>
                        <span>
                          🌿 Kalite ve Tazelik: Yalnızca en taze ve en kaliteli
                          malzemeleri kullanıyoruz. Her bir malzemenin seçimi,
                          yemeğin lezzeti kadar önemli. Bu nedenle, tedarik
                          sürecimizde dikkatli ve özenliyiz.
                        </span>
                        <br></br>
                        <br></br>
                        <span>
                          🎉 Her Etkinlik İçin Özel: İster küçük bir toplantı,
                          ister büyük bir organizasyon olsun, her etkinlik için
                          özel olarak tasarlanmış menülerimizle hizmetinizdeyiz.
                          Etkinliğinizin temasına ve zevkinize göre
                          kişiselleştirilmiş menü seçenekleri sunuyoruz.
                        </span>
                        <br></br>
                        <br></br>
                        <span>
                          🍸 Tamamlayıcı İçecek Seçenekleri: Yemeklerin yanında
                          servis edilen içecekler de bir o kadar önemli. İçecek
                          menümüz, yemeklerimizle mükemmel uyum sağlayacak
                          şekilde hazırlanıyor.
                        </span>
                      </span>
                    </Fragment>
                  )}
                </span>
              </div>
            </div>
            <div className="features16-feature2">
              <img
                alt={props.feature2ImageAlt}
                src={props.feature2ImageSrc}
                className="thq-img-ratio-4-3"
              />
              <div className="features16-content4">
                <h3 className="thq-heading-3">
                  {props.feature2Title ?? (
                    <Fragment>
                      <span className="features16-text24">
                        Tadım Etkinlikleri
                      </span>
                    </Fragment>
                  )}
                </h3>
                <span className="thq-body-small">
                  {props.feature2Description ?? (
                    <Fragment>
                      <span className="features16-text28">
                        <span>
                          🥃 Viski Tadımı:  Dünyanın dört bir yanından gelen
                          seçkin viskilerin gizemli notalarına dalın. Aged
                          Single Malt&apos;tan zengin Blend&apos;lere kadar pek
                          çok viski çeşidini keşfetme fırsatı sizi bekliyor.
                        </span>
                        <br></br>
                        <br></br>
                        <span>
                          🍺 Bira Tadımı:  Ale&apos;den Lager&apos;a,
                          Pilsen&apos;den Stout&apos;a kadar birbirinden farklı
                          bira çeşitlerinin lezzet paletini deneyimleyin. Her
                          bir yudumda, biranın zengin tarihine ve çeşitliliğine
                          tanıklık edin.
                        </span>
                        <br></br>
                        <br></br>
                        <span>
                          🍷 Şarap Tadımı:  Kırmızı, beyaz ve roze... Üzümün
                          sihirli dönüşümüyle oluşan bu lezzetleri keşfedin.
                          Şarapların arkasındaki hikayeleri ve bu eşsiz içeceğin
                          nasıl yapıldığını öğrenin.
                        </span>
                        <br></br>
                        <span> </span>
                      </span>
                    </Fragment>
                  )}
                </span>
              </div>
            </div>
            <div className="features16-feature3">
              <img
                alt={props.feature3ImageAlt}
                src={props.feature3ImageSrc}
                className="thq-img-ratio-4-3"
              />
              <div className="features16-content5">
                <h3 className="thq-heading-3">
                  {props.feature3Title ?? (
                    <Fragment>
                      <span className="features16-text38">Workshoplar</span>
                    </Fragment>
                  )}
                </h3>
                <span className="thq-body-small">
                  {props.feature3Description ?? (
                    <Fragment>
                      <span className="features16-text39">
                        <span>
                          ✨ Kokteyl Yapımı:  Profesyonel mixologistlerin
                          eşliğinde Mojito, Margarita ve Whisky Sour gibi klasik
                          kokteylleri nasıl mükemmel bir şekilde
                          hazırlayacağınızı öğrenin! Hem klasik tarifleri
                          keşfedin hem de bu temel bilgilerle kendi yaratıcı
                          karışımlarınızı oluşturma fırsatı yakalayın.
                        </span>
                        <br></br>
                        <br></br>
                        <span>
                          🍣 Suşi Yapımı:  Deneyimli şeflerimizin rehberliğinde,
                          taze malzemelerle suşi yapımının püf noktalarını
                          öğrenin. Katılımcılarımız, bu eşsiz lezzeti evlerine
                          taşıyabilmenin sırlarını keşfedecekler.
                        </span>
                        <br></br>
                        <br></br>
                        <span>
                          🎨 Yaratıcı Etkinlikler:  Hayal gücünüzü sınırlamayın!
                          Yaratıcılığınızı en üst düzeye çıkaracak atölye
                          çalışmalarımızla, içsel potansiyelinizi ortaya
                          çıkarın. Hangi alanda tutkuluysanız, onu keşfetmek
                          için sizi bekliyoruz.
                        </span>
                      </span>
                    </Fragment>
                  )}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Features16.defaultProps = {
  feature1ImageSrc: '/Photos/finger%20food%20chefs-1400w.webp',
  feature1Description: undefined,
  content1: undefined,
  feature3ImageAlt: 'Unique Decor Options Image',
  feature2Title: undefined,
  heading1: undefined,
  feature2Description: undefined,
  feature1ImageAlt: 'Customized Event Planning Image',
  feature3Title: undefined,
  feature3ImageSrc: '/Photos/cocktail%20workshop-1400w.webp',
  rootClassName: '',
  feature2ImageSrc: '/Photos/whisky%20tasting%202-1400w.webp',
  feature3Description: undefined,
  feature2ImageAlt: 'Professional Staff Image',
  feature1Title: undefined,
}

Features16.propTypes = {
  feature1ImageSrc: PropTypes.string,
  feature1Description: PropTypes.element,
  content1: PropTypes.element,
  feature3ImageAlt: PropTypes.string,
  feature2Title: PropTypes.element,
  heading1: PropTypes.element,
  feature2Description: PropTypes.element,
  feature1ImageAlt: PropTypes.string,
  feature3Title: PropTypes.element,
  feature3ImageSrc: PropTypes.string,
  rootClassName: PropTypes.string,
  feature2ImageSrc: PropTypes.string,
  feature3Description: PropTypes.element,
  feature2ImageAlt: PropTypes.string,
  feature1Title: PropTypes.element,
}

export default Features16
