import React from 'react'

import PropTypes from 'prop-types'

import './gallery5.css'

const Gallery5 = (props) => {
  return (
    <div className="gallery5-gallery3 thq-section-padding">
      <div className="gallery5-max-width thq-section-max-width">
        <div className="gallery5-container1">
          <div className="gallery5-content">
            <div className="gallery5-container2">
              <div className="gallery5-container3">
                <img
                  alt={props.image1Alt}
                  src={props.image1Src}
                  className="gallery5-image1 thq-img-ratio-4-3"
                />
              </div>
              <img
                alt={props.image2Alt}
                src={props.image2Src}
                className="gallery5-image2 thq-img-ratio-1-1"
              />
            </div>
            <div className="gallery5-container4">
              <img
                alt={props.image3Alt}
                src={props.image3Src}
                className="gallery5-image3 thq-img-ratio-1-1"
              />
              <img
                alt={props.image4Alt}
                src={props.image4Src}
                className="gallery5-image4 thq-img-ratio-1-1"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Gallery5.defaultProps = {
  image4Alt: 'Outdoor event with beautiful lighting',
  image2Alt: 'Wedding ceremony with romantic ambiance',
  image3Src: '/Photos/dsc09776-1400w.webp',
  image1Alt: 'Event setup with elegant decorations',
  image4Src: '/orgduzen/elb%20org.%20photo%202-1400w.webp',
  image1Src: '/orgduzen/elb%20org%20dleme%204-1400w.webp',
  image3Alt: 'Indoor event with colorful theme',
  image2Src: '/orgduzen/img_1457-1400w.webp',
}

Gallery5.propTypes = {
  image4Alt: PropTypes.string,
  image2Alt: PropTypes.string,
  image3Src: PropTypes.string,
  image1Alt: PropTypes.string,
  image4Src: PropTypes.string,
  image1Src: PropTypes.string,
  image3Alt: PropTypes.string,
  image2Src: PropTypes.string,
}

export default Gallery5
