import React, { Fragment } from 'react'

import Script from 'dangerous-html/react'
import PropTypes from 'prop-types'

import './logos-partners.css'

const LogosPartners = (props) => {
  return (
    <div
      className={`logos-partners-container1 thq-section-padding ${props.rootClassName} `}
    >
      <div className="logos-partners-max-width thq-section-max-width">
        <h2 className="thq-heading-2">
          {props.heading1 ?? (
            <Fragment>
              <span className="logos-partners-text2">Partnerlerimiz</span>
            </Fragment>
          )}
        </h2>
      </div>
      <div className="logos-partners-container2 thq-animated-group-container-horizontal thq-mask-image-horizontal">
        <div className="thq-animated-group-horizontal">
          <img
            alt={props.logo1Alt}
            src={props.logo1Src}
            className="logos-partners-logo11 thq-img-ratio-16-9"
          />
          <img
            alt={props.logo2Alt}
            src={props.logo2Src}
            className="logos-partners-logo21 thq-img-ratio-16-9"
          />
          <img
            alt={props.logo3Alt}
            src={props.logo3Src}
            className="logos-partners-logo31 thq-img-ratio-16-9"
          />
          <img
            alt={props.logo4Alt}
            src={props.logo4Src}
            className="logos-partners-logo41 thq-img-ratio-16-9"
          />
          <img
            alt={props.logo5Alt}
            src={props.logo5Src}
            className="logos-partners-logo51 thq-img-ratio-16-9"
          />
          <img
            alt={props.logo6Alt}
            src={props.logo6Src}
            className="logos-partners-logo61 thq-img-ratio-16-9"
          />
        </div>
        <div className="thq-animated-group-horizontal">
          <img
            alt={props.logo1Alt}
            src={props.logo1Src}
            className="logos-partners-logo12 thq-img-ratio-16-9"
          />
          <img
            alt={props.logo2Alt}
            src={props.logo2Src}
            className="logos-partners-logo22 thq-img-ratio-16-9"
          />
          <img
            alt={props.logo3Alt}
            src={props.logo3Src}
            className="logos-partners-logo32 thq-img-ratio-16-9"
          />
          <img
            alt={props.logo4Alt}
            src={props.logo4Src}
            className="logos-partners-logo42 thq-img-ratio-16-9"
          />
          <img
            alt={props.logo5Alt}
            src={props.logo5Src}
            className="logos-partners-logo52 thq-img-ratio-16-9"
          />
          <img
            alt={props.logo6Alt}
            src={props.logo6Src}
            className="logos-partners-logo62 thq-img-ratio-16-9"
          />
        </div>
      </div>
      <div>
        <div className="logos-partners-container6">
          <Script
            html={`<style>
  @keyframes scroll-x {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(calc(-100% - 16px));
    }
  }

  @keyframes scroll-y {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(calc(-100% - 16px));
    }
  }
</style>
`}
          ></Script>
        </div>
      </div>
    </div>
  )
}

LogosPartners.defaultProps = {
  logo6Src: '/newlogos/partneraciktim-1-1500w.webp',
  logo3Alt: 'Decoration Services Logo',
  logo5Alt: 'Entertainment Logo',
  logo4Src: '/Logos/imagenw-1500w.webp',
  logo2Alt: 'Event Planning Logo',
  logo1Alt: 'ELB Organizasyon Logo',
  logo2Src: '/Logos/image21-1500w.webp',
  rootClassName: '',
  logo1Src: '/Logos/image23-1500w.webp',
  logo4Alt: 'Catering Logo',
  logo6Alt: 'Contact Us Logo',
  heading1: undefined,
  logo3Src: '/Logos/kirpp-1500w.webp',
  logo5Src: '/newlogos/partner-1500w.webp',
}

LogosPartners.propTypes = {
  logo6Src: PropTypes.string,
  logo3Alt: PropTypes.string,
  logo5Alt: PropTypes.string,
  logo4Src: PropTypes.string,
  logo2Alt: PropTypes.string,
  logo1Alt: PropTypes.string,
  logo2Src: PropTypes.string,
  rootClassName: PropTypes.string,
  logo1Src: PropTypes.string,
  logo4Alt: PropTypes.string,
  logo6Alt: PropTypes.string,
  heading1: PropTypes.element,
  logo3Src: PropTypes.string,
  logo5Src: PropTypes.string,
}

export default LogosPartners
