import React, { Fragment } from 'react'

import { Helmet } from 'react-helmet'

import Navbar from '../components/navbar'
import ContentList12 from '../components/content-list12'
import Footer from '../components/footer'
import './acikrizametni.css'

const Acikrizametni = (props) => {
  return (
    <div className="acikrizametni-container">
      <Helmet>
        <title>ELB Organizasyon</title>
        <meta
          name="description"
          content="ELB Organizasyon, İstanbul 4. Levent ve Beşiktaş’ta özel ve kurumsal etkinlikler sunan, catering ve tadım hizmetleriyle öne çıkan bir organizasyon şirketidir"
        />
        <meta property="og:title" content="ELB Organizasyon" />
        <meta
          property="og:description"
          content="ELB Organizasyon, İstanbul 4. Levent ve Beşiktaş’ta özel ve kurumsal etkinlikler sunan, catering ve tadım hizmetleriyle öne çıkan bir organizasyon şirketidir"
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/d8f84395-ef7e-45f7-bd5b-7c31cdc2ceb7/cb81269f-5301-494a-a792-4549cb492f4c?org_if_sml=1&amp;q=80&amp;force_format=original"
        />
      </Helmet>
      <Navbar
        link1={
          <Fragment>
            <span className="acikrizametni-text10">ANASAYFA</span>
          </Fragment>
        }
        link2={
          <Fragment>
            <span className="acikrizametni-text11">HAKKIMIZDA</span>
          </Fragment>
        }
        link3={
          <Fragment>
            <span className="acikrizametni-text12">HİZMETLER</span>
          </Fragment>
        }
        link4={
          <Fragment>
            <span className="acikrizametni-text13">İLETİŞİM</span>
          </Fragment>
        }
        action1={
          <Fragment>
            <span className="acikrizametni-text14">Hemen Arayın</span>
          </Fragment>
        }
        action2={
          <Fragment>
            <span className="acikrizametni-text15">Instagram</span>
          </Fragment>
        }
      ></Navbar>
      <ContentList12
        content1={
          <Fragment>
            <span className="acikrizametni-text16">
              6698 sayılı Kişisel Verilerin Korunması Kanunu (KVKK) gereği,
              kişisel verilerin işlenmesi için açık rıza alınması gereken
              durumlar için:
            </span>
          </Fragment>
        }
        content2={
          <Fragment>
            <span className="acikrizametni-text17">
              <span>
                ELB Organizasyon Eğitim Turizm ve Ticaret Limited Şirketi
                (“Şirket”) olarak, 6698 sayılı Kişisel Verilerin Korunması
                Kanunu (“KVKK”) uyarınca, kişisel verilerin işlenmesinde
                bireylerin temel hak ve özgürlüklerinin korunmasına büyük önem
                vermekteyiz. Bu doğrultuda, kişisel verilerinizin işlenmesi ve
                aktarılmasına ilişkin olarak tarafınıza açık rıza talep
                ediyoruz.
              </span>
              <br></br>
              <br></br>
              <span>1. KİŞİSEL VERİLERİN İŞLENME AMACI</span>
              <br></br>
              <br></br>
              <span>
                Kişisel verileriniz, aşağıda belirtilen amaçlarla ELB
                Organizasyon tarafından işlenebilir:
              </span>
              <br></br>
              <br></br>
              <span>
                • Hizmetlerimizin sunulması, müşteri ilişkilerinin yönetimi ve
                operasyonel süreçlerin yürütülmesi
              </span>
              <br></br>
              <span>
                • Şirketimiz tarafından sunulan ürün ve hizmetlerle ilgili
                memnuniyet anketleri, pazarlama faaliyetleri, reklam ve tanıtım
                çalışmaları
              </span>
              <br></br>
              <span>
                • Elektronik ticari iletilerin (SMS, e-posta vb.) gönderilmesi
              </span>
              <br></br>
              <span>
                • Etkinlik organizasyonlarının planlanması, kayıt ve katılım
                süreçlerinin yönetimi
              </span>
              <br></br>
              <span>
                • Yasal yükümlülüklerin yerine getirilmesi ve ilgili kamu
                kurumlarına bilgi verilmesi
              </span>
              <br></br>
              <span>
                • Sözleşme süreçlerinin yürütülmesi ve iş faaliyetlerinin
                devamlılığının sağlanması
              </span>
              <br></br>
              <br></br>
              <span>2. KİŞİSEL VERİLERİN AKTARILMASI</span>
              <br></br>
              <br></br>
              <span>
                Kişisel verileriniz, yukarıda belirtilen amaçlar doğrultusunda;
              </span>
              <br></br>
              <br></br>
              <span>• İş ortaklarımıza, hizmet sağlayıcılarımıza,</span>
              <br></br>
              <span>
                • Yasal düzenlemeler gereğince kamu kurumlarına ve yetkili
                mercilere,
              </span>
              <br></br>
              <span>
                • Yurt içinde veya yurt dışında bulunan işbirliği yaptığımız
                üçüncü kişilere aktarılabilir.
              </span>
              <br></br>
              <br></br>
              <span>3. KİŞİSEL VERİLERİN İŞLENME YÖNTEMİ</span>
              <br></br>
              <br></br>
              <span>
                Kişisel verileriniz, sözlü, yazılı ya da elektronik ortamda, web
                sitemiz, mobil uygulamalarımız, etkinliklerimize kayıt formları,
                müşteri hizmetleri, e-posta yazışmaları gibi kanallar
                aracılığıyla toplanabilir. Bu kişisel veriler, otomatik veya
                otomatik olmayan yöntemlerle işlenebilir.
              </span>
              <br></br>
              <br></br>
              <span>4. KİŞİSEL VERİLERİNİZ İLE İLGİLİ HAKLARINIZ</span>
              <br></br>
              <br></br>
              <span>
                KVKK’nın 11. maddesi kapsamında sahip olduğunuz haklar
                şunlardır:
              </span>
              <br></br>
              <br></br>
              <span>
                • Kişisel verilerinizin işlenip işlenmediğini öğrenme,
              </span>
              <br></br>
              <span>
                • Kişisel verileriniz işlenmişse buna ilişkin bilgi talep etme,
              </span>
              <br></br>
              <span>
                • Kişisel verilerin işlenme amacını ve bunların amacına uygun
                kullanılıp kullanılmadığını öğrenme,
              </span>
              <br></br>
              <span>
                • Yurt içinde veya yurt dışında kişisel verilerinizin
                aktarıldığı üçüncü kişileri bilme,
              </span>
              <br></br>
              <span>
                • Kişisel verilerinizin eksik veya yanlış işlenmiş olması
                halinde bunların düzeltilmesini isteme,
              </span>
              <br></br>
              <span>
                • Kişisel verilerinizin silinmesini veya yok edilmesini isteme,
              </span>
              <br></br>
              <span>
                • İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla
                analiz edilmesi suretiyle aleyhinize bir sonucun ortaya
                çıkmasına itiraz etme,
              </span>
              <br></br>
              <span>
                • Kişisel verilerinizin kanuna aykırı olarak işlenmesi sebebiyle
                zarara uğramanız halinde bu zararın giderilmesini talep etme.
              </span>
              <br></br>
              <br></br>
              <span>5. AÇIK RIZA BEYANI</span>
              <br></br>
              <br></br>
              <span>
                İşbu Açık Rıza Metni kapsamında, kişisel verilerimin ELB
                Organizasyon tarafından yukarıda belirtilen amaçlarla
                işlenmesini ve gerekli durumlarda üçüncü kişilerle
                paylaşılmasını kabul ediyorum.
              </span>
              <br></br>
              <br></br>
              <span>İsim-Soyisim:</span>
              <br></br>
              <span>Tarih:</span>
              <br></br>
              <span>İmza:</span>
              <br></br>
              <br></br>
              <span>
                Bu açık rıza metni, ELB Organizasyon’un kişisel verileri
                toplarken yasal sorumluluklarını yerine getirebilmesi için
                tasarlanmıştır. Açık rıza alınacak durumlarda, bu metni
                kullanarak kişisel veri sahiplerinden yasal olarak geçerli bir
                onay alabilirsiniz.
              </span>
            </span>
          </Fragment>
        }
        heading2={
          <Fragment>
            <span className="acikrizametni-text96">
              <span>AÇIK RIZA METNİ</span>
              <br></br>
            </span>
          </Fragment>
        }
      ></ContentList12>
      <Footer
        link6={
          <Fragment>
            <span className="acikrizametni-text99">Anasayfa</span>
          </Fragment>
        }
        link7={
          <Fragment>
            <span className="acikrizametni-text100">Hakkımızda</span>
          </Fragment>
        }
        link8={
          <Fragment>
            <span className="acikrizametni-text101">Hizmetler</span>
          </Fragment>
        }
        link9={
          <Fragment>
            <span className="acikrizametni-text102">İletişim</span>
          </Fragment>
        }
        link10={
          <Fragment>
            <span className="acikrizametni-text103">Hemen Teklif Alın!</span>
          </Fragment>
        }
        copyright={
          <Fragment>
            <span className="acikrizametni-text104">
              © 2024 ELB Organizasyon. Tüm hakları saklıdır.
            </span>
          </Fragment>
        }
        column2Title={
          <Fragment>
            <span className="acikrizametni-text105">Site Haritası</span>
          </Fragment>
        }
        rootClassName="footerroot-class-name5"
      ></Footer>
    </div>
  )
}

export default Acikrizametni
