import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import './navbar.css'

const Navbar = (props) => {
  return (
    <header className={`navbar-container ${props.rootClassName} `}>
      <header data-thq="thq-navbar" className="navbar-navbar-interactive">
        <img
          alt={props.logoAlt}
          src={props.logoSrc}
          className="navbar-image1"
        />
        <div data-thq="thq-navbar-nav" className="navbar-desktop-menu">
          <nav className="navbar-links1">
            <Link to="/" className="navbar-link11 thq-body-small thq-link">
              {props.link1 ?? (
                <Fragment>
                  <span className="navbar-text3">ANASAYFA</span>
                </Fragment>
              )}
            </Link>
            <Link
              to="/hakkimizda"
              className="navbar-link21 thq-body-small thq-link"
            >
              {props.link2 ?? (
                <Fragment>
                  <span className="navbar-text5">HAKKIMIZDA</span>
                </Fragment>
              )}
            </Link>
            <Link
              to="/hizmetler"
              className="navbar-link31 thq-body-small thq-link"
            >
              {props.link3 ?? (
                <Fragment>
                  <span className="navbar-text6">HİZMETLER</span>
                </Fragment>
              )}
            </Link>
            <Link
              to="/iletisim"
              className="navbar-link41 thq-body-small thq-link"
            >
              {props.link4 ?? (
                <Fragment>
                  <span className="navbar-text4">İLETİŞİM</span>
                </Fragment>
              )}
            </Link>
          </nav>
          <div className="navbar-buttons1">
            <a
              href="tel:+905362326673"
              className="navbar-action11 thq-button-filled thq-button-animated"
            >
              <span>
                {props.action1 ?? (
                  <Fragment>
                    <span className="navbar-text8">Hemen Arayın</span>
                  </Fragment>
                )}
              </span>
            </a>
            <a
              href="https://www.instagram.com/elborganizasyon?utm_source=ig_web_button_share_sheet&amp;igsh=ZDNlZDc0MzIxNw=="
              target="_blank"
              rel="noreferrer noopener"
              className="navbar-action21 thq-button-outline thq-button-animated"
            >
              <span>
                {props.action2 ?? (
                  <Fragment>
                    <span className="navbar-text7">Instagram</span>
                  </Fragment>
                )}
              </span>
            </a>
          </div>
        </div>
        <div data-thq="thq-burger-menu" className="navbar-burger-menu">
          <svg viewBox="0 0 1024 1024" className="navbar-icon1">
            <path d="M128 554.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 298.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667zM128 810.667h768c23.552 0 42.667-19.115 42.667-42.667s-19.115-42.667-42.667-42.667h-768c-23.552 0-42.667 19.115-42.667 42.667s19.115 42.667 42.667 42.667z"></path>
          </svg>
        </div>
        <div data-thq="thq-mobile-menu" className="navbar-mobile-menu">
          <div className="navbar-nav">
            <div className="navbar-top">
              <img
                alt={props.logoAlt}
                src={props.logoSrc}
                className="navbar-logo"
              />
              <div data-thq="thq-close-menu" className="navbar-close-menu">
                <svg viewBox="0 0 1024 1024" className="navbar-icon3">
                  <path d="M810 274l-238 238 238 238-60 60-238-238-238 238-60-60 238-238-238-238 60-60 238 238 238-238z"></path>
                </svg>
              </div>
            </div>
            <nav className="navbar-links2">
              <Link to="/" className="navbar-link12 thq-body-small thq-link">
                {props.link1 ?? (
                  <Fragment>
                    <span className="navbar-text3">ANASAYFA</span>
                  </Fragment>
                )}
              </Link>
              <Link
                to="/hakkimizda"
                className="navbar-link22 thq-body-small thq-link"
              >
                {props.link2 ?? (
                  <Fragment>
                    <span className="navbar-text5">HAKKIMIZDA</span>
                  </Fragment>
                )}
              </Link>
              <Link
                to="/hizmetler"
                className="navbar-link32 thq-body-small thq-link"
              >
                {props.link3 ?? (
                  <Fragment>
                    <span className="navbar-text6">HİZMETLER</span>
                  </Fragment>
                )}
              </Link>
              <Link
                to="/iletisim"
                className="navbar-link42 thq-body-small thq-link"
              >
                {props.link4 ?? (
                  <Fragment>
                    <span className="navbar-text4">İLETİŞİM</span>
                  </Fragment>
                )}
              </Link>
            </nav>
            <div className="navbar-buttons2">
              <button className="thq-button-filled">
                <a href="tel:+905362326673">
                  {props.action1 ?? (
                    <Fragment>
                      <span className="navbar-text8">Hemen Arayın</span>
                    </Fragment>
                  )}
                </a>
              </button>
              <button className="thq-button-outline">
                <a
                  href="https://www.instagram.com/elborganizasyon?utm_source=ig_web_button_share_sheet&amp;igsh=ZDNlZDc0MzIxNw=="
                  target="_blank"
                  rel="noreferrer noopener"
                >
                  {props.action2 ?? (
                    <Fragment>
                      <span className="navbar-text7">Instagram</span>
                    </Fragment>
                  )}
                </a>
              </button>
            </div>
          </div>
        </div>
      </header>
    </header>
  )
}

Navbar.defaultProps = {
  link1: undefined,
  link4: undefined,
  rootClassName: '',
  logoAlt: 'Company Logo',
  link2: undefined,
  link3: undefined,
  logoSrc: '/Logos/whatsapp%20image%202024-10-01%20at%2016.35.06-1500h.webp',
  action2: undefined,
  action1: undefined,
}

Navbar.propTypes = {
  link1: PropTypes.element,
  link4: PropTypes.element,
  rootClassName: PropTypes.string,
  logoAlt: PropTypes.string,
  link2: PropTypes.element,
  link3: PropTypes.element,
  logoSrc: PropTypes.string,
  action2: PropTypes.element,
  action1: PropTypes.element,
}

export default Navbar
