import React, { Fragment } from 'react'

import { Helmet } from 'react-helmet'

import Navbar from '../components/navbar'
import ContactForm7 from '../components/contact-form7'
import ElbLogos from '../components/elb-logos'
import Footer from '../components/footer'
import './iletisim.css'

const Iletisim = (props) => {
  return (
    <div className="iletisim-container">
      <Helmet>
        <title>ELB Organizasyon</title>
        <meta
          name="description"
          content="ELB Organizasyon, İstanbul 4. Levent ve Beşiktaş’ta özel ve kurumsal etkinlikler sunan, catering ve tadım hizmetleriyle öne çıkan bir organizasyon şirketidir"
        />
        <meta property="og:title" content="ELB Organizasyon" />
        <meta
          property="og:description"
          content="ELB Organizasyon, İstanbul 4. Levent ve Beşiktaş’ta özel ve kurumsal etkinlikler sunan, catering ve tadım hizmetleriyle öne çıkan bir organizasyon şirketidir"
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/d8f84395-ef7e-45f7-bd5b-7c31cdc2ceb7/cb81269f-5301-494a-a792-4549cb492f4c?org_if_sml=1&amp;q=80&amp;force_format=original"
        />
      </Helmet>
      <Navbar
        link1={
          <Fragment>
            <span className="iletisim-text10">ANASAYFA</span>
          </Fragment>
        }
        link2={
          <Fragment>
            <span className="iletisim-text11">HAKKIMIZDA</span>
          </Fragment>
        }
        link3={
          <Fragment>
            <span className="iletisim-text12">HİZMETLER</span>
          </Fragment>
        }
        link4={
          <Fragment>
            <span className="iletisim-text13">İLETİŞİM</span>
          </Fragment>
        }
        action1={
          <Fragment>
            <span className="iletisim-text14">Hemen Arayın</span>
          </Fragment>
        }
        action2={
          <Fragment>
            <span className="iletisim-text15">Instagram</span>
          </Fragment>
        }
        rootClassName="navbarroot-class-name2"
      ></Navbar>
      <ContactForm7
        text={
          <Fragment>
            <span className="iletisim-text16">Hemen Teklif Alın!</span>
          </Fragment>
        }
        email={
          <Fragment>
            <span className="iletisim-text17">info@elborganizasyon.com</span>
          </Fragment>
        }
        phone={
          <Fragment>
            <span className="iletisim-text18">+90 536 232 6673</span>
          </Fragment>
        }
        action={
          <Fragment>
            <span className="iletisim-text19">Gönder</span>
          </Fragment>
        }
        address={
          <Fragment>
            <span className="iletisim-text20">
              <span>4. Levent Konaklar Mah. Selvili Sk.</span>
              <br></br>
              <span>No. 8/B Beşiktaş / İstanbul</span>
            </span>
          </Fragment>
        }
        content1={
          <Fragment>
            <span className="iletisim-text24">
              <span>Bize Ulaşın</span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </span>
          </Fragment>
        }
        content2={
          <Fragment>
            <span className="iletisim-text27">
              <span>
                Hayallerinizi Gerçeğe Dönüştürmek İçin Bizimle İletişime Geçin!
              </span>
              <br></br>
            </span>
          </Fragment>
        }
        heading1={
          <Fragment>
            <span className="iletisim-text30">İletişim Bilgileri</span>
          </Fragment>
        }
      ></ContactForm7>
      <ElbLogos
        heading1={
          <Fragment>
            <span className="iletisim-text31">ELB GRUP</span>
          </Fragment>
        }
        rootClassName="elb-logosroot-class-name"
      ></ElbLogos>
      <Footer
        link6={
          <Fragment>
            <span className="iletisim-text32">Anasayfa</span>
          </Fragment>
        }
        link7={
          <Fragment>
            <span className="iletisim-text33">Hakkımızda</span>
          </Fragment>
        }
        link8={
          <Fragment>
            <span className="iletisim-text34">Hizmetler</span>
          </Fragment>
        }
        link9={
          <Fragment>
            <span className="iletisim-text35">İletişim</span>
          </Fragment>
        }
        link10={
          <Fragment>
            <span className="iletisim-text36">Hemen Teklif Alın!</span>
          </Fragment>
        }
        copyright={
          <Fragment>
            <span className="iletisim-text37">
              © 2024 ELB Organizasyon. Tüm hakları saklıdır.
            </span>
          </Fragment>
        }
        column2Title={
          <Fragment>
            <span className="iletisim-text38">Site Haritası</span>
          </Fragment>
        }
      ></Footer>
    </div>
  )
}

export default Iletisim
