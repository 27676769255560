import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import './contact-form7.css';

const ContactForm7 = (props) => {
  const handleSubmit = async (e) => {
    e.preventDefault(); // Sayfanın yeniden yüklenmesini engeller

    const form = e.target; 
    const data = new FormData(form); // Form verilerini toplar
    const formData = Object.fromEntries(data.entries()); // Form verilerini nesne haline getirir

    try {
      const response = await fetch('https://qc6x38tsf9.execute-api.eu-central-1.amazonaws.com/prod/submit-form', {
        method: 'POST',
        body: JSON.stringify(formData), // Form verilerini JSON formatında gönderir
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        form.reset(); // Formu sıfırlar
        alert('Form başarıyla gönderildi!'); // Tarayıcı mesajı (alert)
      } else {
        alert('Form gönderilirken bir hata oluştu.'); // Tarayıcı mesajı (alert)
      }
    } catch (error) {
      alert('Bir hata oluştu: ' + error.message); // Tarayıcı mesajı (alert)
    }
  };

  return (
    <div className="contact-form7-contact1 thq-section-padding">
      <div className="contact-form7-max-width thq-flex-row thq-section-max-width">
        <div className="contact-form7-section-title thq-flex-column">
          <span>
            {props.content2 ?? (
              <span className="contact-form7-text25 thq-body-small">
                Hayallerinizi Gerçeğe Dönüştürmek İçin Bizimle İletişime Geçin!
                <br />
              </span>
            )}
          </span>
          <div className="contact-form7-content1">
            <h2 className="contact-form7-text39 thq-heading-2">
              {props.heading1 ?? "İletişim Bilgileri"}
            </h2>
            <span>
              {props.content1 ?? (
                <span className="contact-form7-text32 thq-body-small">
                  <span>Bize Ulaşın</span>
                  <span>
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                </span>
              )}
            </span>
          </div>

          <div className="contact-form7-content2">
            <div className="contact-form7-row1">
              <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                <path d="M854 342v-86l-342 214-342-214v86l342 212zM854 170q34 0 59 26t25 60v512q0 34-25 60t-59 26h-684q-34 0-59-26t-25-60v-512q0-34 25-60t59-26h684z"></path>
              </svg>
              <span>
                {props.email ?? (
                  <span className="contact-form7-text31 thq-body-small">
                    info@elborganizasyon.com
                  </span>
                )}
              </span>
            </div>
            <div className="contact-form7-row2">
              <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                <path d="M282 460q96 186 282 282l94-94q20-20 44-10 72 24 152 24 18 0 30 12t12 30v150q0 18-12 30t-30 12q-300 0-513-213t-213-513q0-18 12-30t30-12h150q18 0 30 12t12 30q0 80 24 152 8 26-10 44z"></path>
              </svg>
              <span>
                {props.phone ?? (
                  <span className="contact-form7-text30 thq-body-small">
                    +90 536 232 6673
                  </span>
                )}
              </span>
            </div>
            <div className="contact-form7-row3">
              <svg viewBox="0 0 1024 1024" className="thq-icon-small">
                <path d="M512 0c-176.732 0-320 143.268-320 320 0 320 320 704 320 704s320-384 320-704c0-176.732-143.27-320-320-320zM512 512c-106.040 0-192-85.96-192-192s85.96-192 192-192 192 85.96 192 192-85.96 192-192 192z"></path>
              </svg>
              <span>
                {props.address ?? (
                  <span className="contact-form7-text35 thq-body-small">
                    <span>4. Levent Konaklar Mah. Selvili Sk.</span>
                    <br />
                    <span>No. 8/B Beşiktaş / İstanbul</span>
                  </span>
                )}
              </span>
            </div>
          </div>

          <iframe
            src="https://www.google.com/maps?q=capital bar istanbul&amp;output=embed"
            address="capital bar istanbul"
            className="contact-form7-google-maps"
          ></iframe>
        </div>

        <form onSubmit={handleSubmit} className="contact-form7-form thq-flex-column">
          <span>
            {props.text ?? (
              <span className="contact-form7-text29">Hemen Teklif Alın!</span>
            )}
          </span>

          <div className="contact-form7-container1">
            <div className="contact-form7-input1">
              <label htmlFor="contact-form-7-first-name" className="thq-body-small">
                Ad Soyad (Şirket Adı)*
              </label>
              <input
                type="text"
                id="contact-form-7-first-name"
                name="name"
                required
                placeholder="Ad Soyad (Şirket Adı)"
                className="thq-input"
              />
            </div>
            <div className="contact-form7-input2">
              <label htmlFor="contact-form-7-email" className="thq-body-small">
                Email*
              </label>
              <input
                type="email"
                id="contact-form-7-email"
                name="email"
                required
                placeholder="Email"
                className="thq-input"
              />
            </div>
          </div>

          <div className="contact-form7-container2">
            <div className="contact-form7-input3">
              <label htmlFor="contact-form-7-phone" className="thq-body-small">
                Telefon Numarası*
              </label>
              <input
                type="tel"
                id="contact-form-7-phone"
                name="phone"
                required
                placeholder="Telefon Numarası"
                className="thq-input"
              />
            </div>
            <div className="contact-form7-input4">
              <label htmlFor="contact-form-7-city" className="thq-body-small">
                Şehir*
              </label>
              <input
                type="text"
                id="contact-form-7-city"
                name="city"
                required
                placeholder="Şehir"
                className="thq-input"
              />
            </div>
          </div>

          <div className="contact-form7-container3">
            <div className="contact-form7-input5">
              <label htmlFor="contact-form-7-person" className="thq-body-small">
                Kişi Sayısı*
              </label>
              <input
                type="number"
                id="contact-form-7-person"
                name="person"
                required
                placeholder="Kişi Sayısı"
                className="thq-input"
              />
            </div>
            <div className="contact-form7-input6">
              <label htmlFor="contact-form-7-date" className="thq-body-small">
                Tarih*
              </label>
              <input
                type="date"
                id="contact-form-7-date"
                name="date"
                required
                className="thq-input"
              />
            </div>
          </div>

          <div className="contact-form7-container4">
            <div className="contact-form7-input7">
              <label htmlFor="contact-form-7-message" className="thq-body-small">
                Mesaj
              </label>
              <textarea
                id="contact-form-7-message"
                name="message"
                required
                rows="3"
                placeholder="Size daha iyi bir teklif sunabilmemiz için ortalama katılımcı sayısı, etkinliğin gerçekleşeceği şehir ve etkinlik detaylarını lütfen paylaşınız"
                className="thq-input"
              ></textarea>
            </div>
          </div>

          <div className="contact-form7-checkbox1">
            <input
              type="checkbox"
              id="contact-form-7-check"
              name="kvkk"
              value="Kabul ediyorum"
              required
              className="thq-checkbox"
            />
            <label htmlFor="contact-form-7-check" className="thq-body-small">
              <Link to="/kvkk" className="contact-form7-navlink1">
                Kişisel Verilerin Korunması Kanunu (KVKK)
              </Link>
              <span> Kabul ediyorum.</span>
            </label>
          </div>

          <div className="contact-form7-checkbox2">
            <input
              type="checkbox"
              id="contact-form-7-check2"
              name="acikRiza"
              value="Onaylıyorum"
              required
              className="thq-checkbox"
            />
            <label htmlFor="contact-form-7-check2" className="thq-body-small">
              <Link to="/acikrizametni" className="contact-form7-navlink2">
                Açık Rıza Metnini
              </Link>
              <span> onaylıyorum.</span>
            </label>
          </div>

          <button type="submit" className="contact-form7-button thq-button-filled">
            <span>{props.action ?? <span className="contact-form7-text28 thq-body-small">Gönder</span>}</span>
          </button>
        </form>
      </div>
    </div>
  );
};

ContactForm7.defaultProps = {
  content2: undefined,
  action: undefined,
  text: undefined,
  phone: undefined,
  email: undefined,
  content1: undefined,
  address: undefined,
  heading1: undefined,
};

ContactForm7.propTypes = {
  content2: PropTypes.element,
  action: PropTypes.element,
  text: PropTypes.element,
  phone: PropTypes.element,
  email: PropTypes.element,
  content1: PropTypes.element,
  address: PropTypes.element,
  heading1: PropTypes.element,
};

export default ContactForm7;