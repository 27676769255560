import React, { Fragment } from 'react'

import Script from 'dangerous-html/react'
import PropTypes from 'prop-types'

import './logos-references.css'

const LogosReferences = (props) => {
  return (
    <div
      className={`logos-references-container1 thq-section-padding ${props.rootClassName} `}
    >
      <div className="logos-references-max-width thq-section-max-width">
        <h2 className="thq-heading-2">
          {props.heading1 ?? (
            <Fragment>
              <span className="logos-references-text2">Referanslarımız</span>
            </Fragment>
          )}
        </h2>
      </div>
      <div className="logos-references-container2 thq-animated-group-container-horizontal thq-mask-image-horizontal">
        <div className="thq-animated-group-horizontal">
          <img
            alt={props.logo1Alt}
            src={props.logo1Src}
            className="logos-references-logo11 thq-img-ratio-16-9"
          />
          <img
            alt={props.logo2Alt}
            src={props.logo2Src}
            className="logos-references-logo21 thq-img-ratio-16-9"
          />
          <img
            alt={props.logo3Alt}
            src={props.logo3Src}
            className="logos-references-logo31 thq-img-ratio-16-9"
          />
          <img
            alt={props.logo4Alt}
            src={props.logo4Src}
            className="logos-references-logo41 thq-img-ratio-16-9"
          />
          <img
            alt={props.logo5Alt}
            src={props.logo5Src}
            className="logos-references-logo51 thq-img-ratio-16-9"
          />
          <img
            alt={props.logo5Alt1}
            src={props.logo5Src1}
            className="logos-references-logo61 thq-img-ratio-16-9"
          />
          <img
            alt={props.logo5Alt12}
            src={props.logo5Src12}
            className="logos-references-logo71 thq-img-ratio-16-9"
          />
        </div>
        <div className="thq-animated-group-horizontal">
          <img
            alt={props.logo1Alt}
            src={props.logo1Src}
            className="logos-references-logo12 thq-img-ratio-16-9"
          />
          <img
            alt={props.logo2Alt}
            src={props.logo2Src}
            className="logos-references-logo22 thq-img-ratio-16-9"
          />
          <img
            alt={props.logo3Alt}
            src={props.logo3Src}
            className="logos-references-logo32 thq-img-ratio-16-9"
          />
          <img
            alt={props.logo4Alt}
            src={props.logo4Src}
            className="logos-references-logo42 thq-img-ratio-16-9"
          />
          <img
            alt={props.logo5Alt}
            src={props.logo5Src}
            className="logos-references-logo52 thq-img-ratio-16-9"
          />
          <img
            alt={props.logo5Alt11}
            src={props.logo5Src11}
            className="logos-references-logo62 thq-img-ratio-16-9"
          />
          <img
            alt={props.logo5Alt111}
            src={props.logo5Src111}
            className="logos-references-logo72 thq-img-ratio-16-9"
          />
        </div>
      </div>
      <div>
        <div className="logos-references-container6">
          <Script
            html={`<style>
  @keyframes scroll-x {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(calc(-100% - 16px));
    }
  }

  @keyframes scroll-y {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(calc(-100% - 16px));
    }
  }
</style>
`}
          ></Script>
        </div>
      </div>
    </div>
  )
}

LogosReferences.defaultProps = {
  logo5Src12: '/newlogos/referanstuclasslogo-1-1500w.webp',
  logo5Src1: '/Logos/whatsapp%20image%202024-10-15%20at%2017.54.52-1500w.webp',
  logo4Alt: 'Catering Logo',
  heading1: undefined,
  logo5Src: '/Logos/image20-1500w.webp',
  logo3Alt: 'Decoration Services Logo',
  rootClassName: '',
  logo2Src: '/Logos/image27-1500w.webp',
  logo1Alt: 'ELB Organizasyon Logo',
  logo5Src111: '/newlogos/referanstuclasslogo-1-1500w.webp',
  logo5Alt12: 'Entertainment Logo',
  logo4Src: '/Logos/image22-1500w.webp',
  logo2Alt: 'Event Planning Logo',
  logo5Alt111: 'Entertainment Logo',
  logo5Alt: 'Entertainment Logo',
  logo5Alt11: 'Entertainment Logo',
  logo5Src11: '/Logos/whatsapp%20image%202024-10-15%20at%2017.54.52-1500w.webp',
  logo5Alt1: 'Entertainment Logo',
  logo3Src: '/Logos/image29-1500w.webp',
  logo1Src: '/Logos/image19-1500w.webp',
}

LogosReferences.propTypes = {
  logo5Src12: PropTypes.string,
  logo5Src1: PropTypes.string,
  logo4Alt: PropTypes.string,
  heading1: PropTypes.element,
  logo5Src: PropTypes.string,
  logo3Alt: PropTypes.string,
  rootClassName: PropTypes.string,
  logo2Src: PropTypes.string,
  logo1Alt: PropTypes.string,
  logo5Src111: PropTypes.string,
  logo5Alt12: PropTypes.string,
  logo4Src: PropTypes.string,
  logo2Alt: PropTypes.string,
  logo5Alt111: PropTypes.string,
  logo5Alt: PropTypes.string,
  logo5Alt11: PropTypes.string,
  logo5Src11: PropTypes.string,
  logo5Alt1: PropTypes.string,
  logo3Src: PropTypes.string,
  logo1Src: PropTypes.string,
}

export default LogosReferences
