import React from 'react'
import ReactDOM from 'react-dom'
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom'

import './style.css'
import Acikrizametni from './views/acikrizametni'
import Kvkk from './views/kvkk'
import Hakkimizda from './views/hakkimizda'
import Anasayfa from './views/anasayfa'
import Hizmetler from './views/hizmetler'
import Iletisim from './views/iletisim'
import NotFound from './views/not-found'

const App = () => {
  return (
    <Router>
      <Switch>
        <Route component={Acikrizametni} exact path="/acikrizametni" />
        <Route component={Kvkk} exact path="/kvkk" />
        <Route component={Hakkimizda} exact path="/hakkimizda" />
        <Route component={Anasayfa} exact path="/" />
        <Route component={Hizmetler} exact path="/hizmetler" />
        <Route component={Iletisim} exact path="/iletisim" />
        <Route component={NotFound} path="**" />
        <Redirect to="**" />
      </Switch>
    </Router>
  )
}

ReactDOM.render(<App />, document.getElementById('app'))
