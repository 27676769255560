import React, { Fragment } from 'react'
import { Link } from 'react-router-dom'

import PropTypes from 'prop-types'

import './hero12.css'

const Hero12 = (props) => {
  return (
    <div className="hero12-header76">
      <div className="hero12-max-width thq-section-max-width">
        <div className="hero12-column1 thq-section-padding">
          <h1 className="hero12-text10 thq-heading-1">
            {props.heading1 ?? (
              <Fragment>
                <span className="hero12-text14">
                  <span>
                    SİZ HAYAL EDİN,
                    <span
                      dangerouslySetInnerHTML={{
                        __html: ' ',
                      }}
                    />
                  </span>
                  <br></br>
                  <span>BİZ GERÇEKLEŞTİRELİM</span>
                </span>
              </Fragment>
            )}
          </h1>
          <p className="thq-body-large hero12-text11">
            {props.content1 ?? (
              <Fragment>
                <span className="hero12-text20">
                  ELB Organizasyon olarak, etkinliklerinizi unutulmaz kılmak
                  için yanınızdayız!
                </span>
              </Fragment>
            )}
          </p>
          <div className="hero12-actions">
            <Link to="/hizmetler" className="hero12-button1 thq-button-filled">
              <span className="thq-body-small">
                {props.action1 ?? (
                  <Fragment>
                    <span className="hero12-text19">Hizmetleri Keşfedin</span>
                  </Fragment>
                )}
              </span>
            </Link>
            <Link to="/iletisim" className="hero12-button2 thq-button-outline">
              <span className="thq-body-small">
                {props.action2 ?? (
                  <Fragment>
                    <span className="hero12-text18">Teklif Al</span>
                  </Fragment>
                )}
              </span>
            </Link>
          </div>
        </div>
        <div className="hero12-content">
          <div className="hero12-column2">
            <img
              alt={props.image1Alt}
              src={props.image1Src}
              className="hero12-placeholder-image1 thq-img-ratio-1-1"
            />
            <img
              alt={props.image2Alt}
              src={props.image2Src}
              className="hero12-placeholder-image2 thq-img-ratio-1-1"
            />
            <img
              alt={props.image3Alt}
              src={props.image3Src}
              className="hero12-placeholder-image3 thq-img-ratio-1-1"
            />
          </div>
          <div className="hero12-column3">
            <img
              alt={props.image4Alt}
              src={props.image4Src}
              className="hero12-placeholder-image4 thq-img-ratio-1-1"
            />
            <img
              alt={props.image5Alt}
              src={props.image5Src}
              className="hero12-placeholder-image5 thq-img-ratio-1-1"
            />
            <img
              alt={props.image6Alt}
              src={props.image6Src}
              className="hero12-placeholder-image6 thq-img-ratio-1-1"
            />
            <img
              alt={props.image7Alt}
              src={props.image7Src}
              className="hero12-placeholder-image7 thq-img-ratio-1-1"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

Hero12.defaultProps = {
  heading1: undefined,
  image4Alt: 'Image of secure payments',
  image6Src: '/orgduzen/dzenleme%207-400h.webp',
  image7Src: '/Photos/image8-400w.webp',
  action2: undefined,
  image2Src: '/orgduzen/elb%20org.%20photo%202-700w.webp',
  image3Src: '/orgduzen/whisky%20tasting%20-700w.webp',
  image5Src: '/orgduzen/cocktail%20workshop%202-400h.webp',
  image1Alt: 'Image of happy customers',
  image4Src: '/Photos/image8-400w.webp',
  action1: undefined,
  image3Alt: 'Image of fast delivery',
  image6Alt: 'Image of quality guarantee',
  image1Src: '/orgduzen/letter%20with%20elb%20org.-700w.webp',
  image2Alt: 'Image of diverse products',
  content1: undefined,
  image5Alt: 'Image of customer support',
  image7Alt: 'Image of latest trends',
}

Hero12.propTypes = {
  heading1: PropTypes.element,
  image4Alt: PropTypes.string,
  image6Src: PropTypes.string,
  image7Src: PropTypes.string,
  action2: PropTypes.element,
  image2Src: PropTypes.string,
  image3Src: PropTypes.string,
  image5Src: PropTypes.string,
  image1Alt: PropTypes.string,
  image4Src: PropTypes.string,
  action1: PropTypes.element,
  image3Alt: PropTypes.string,
  image6Alt: PropTypes.string,
  image1Src: PropTypes.string,
  image2Alt: PropTypes.string,
  content1: PropTypes.element,
  image5Alt: PropTypes.string,
  image7Alt: PropTypes.string,
}

export default Hero12
