import React, { Fragment } from 'react'

import { Helmet } from 'react-helmet'

import Navbar from '../components/navbar'
import Features6 from '../components/features6'
import ElbLogos from '../components/elb-logos'
import Gallery9 from '../components/gallery9'
import Footer from '../components/footer'
import './hakkimizda.css'

const Hakkimizda = (props) => {
  return (
    <div className="hakkimizda-container">
      <Helmet>
        <title>ELB Organizasyon</title>
        <meta
          name="description"
          content="ELB Organizasyon, İstanbul 4. Levent ve Beşiktaş’ta özel ve kurumsal etkinlikler sunan, catering ve tadım hizmetleriyle öne çıkan bir organizasyon şirketidir"
        />
        <meta property="og:title" content="ELB Organizasyon" />
        <meta
          property="og:description"
          content="ELB Organizasyon, İstanbul 4. Levent ve Beşiktaş’ta özel ve kurumsal etkinlikler sunan, catering ve tadım hizmetleriyle öne çıkan bir organizasyon şirketidir"
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/d8f84395-ef7e-45f7-bd5b-7c31cdc2ceb7/cb81269f-5301-494a-a792-4549cb492f4c?org_if_sml=1&amp;q=80&amp;force_format=original"
        />
      </Helmet>
      <Navbar
        link1={
          <Fragment>
            <span className="hakkimizda-text10">ANASAYFA</span>
          </Fragment>
        }
        link2={
          <Fragment>
            <span className="hakkimizda-text11">HAKKIMIZDA</span>
          </Fragment>
        }
        link3={
          <Fragment>
            <span className="hakkimizda-text12">HİZMETLER</span>
          </Fragment>
        }
        link4={
          <Fragment>
            <span className="hakkimizda-text13">İLETİŞİM</span>
          </Fragment>
        }
        action1={
          <Fragment>
            <span className="hakkimizda-text14">Hemen Arayın</span>
          </Fragment>
        }
        action2={
          <Fragment>
            <span className="hakkimizda-text15">Instagram</span>
          </Fragment>
        }
        rootClassName="navbarroot-class-name"
      ></Navbar>
      <Features6
        mainAction={
          <Fragment>
            <span className="hakkimizda-text16">Hizmetleri Keşfedin</span>
          </Fragment>
        }
        sectionTitle={
          <Fragment>
            <span className="hakkimizda-text17">Biz Kimiz?</span>
          </Fragment>
        }
        rootClassName="features6root-class-name"
        secondaryAction={
          <Fragment>
            <span className="hakkimizda-text18">İletişime Geçin</span>
          </Fragment>
        }
        featureDescription={
          <Fragment>
            <span className="hakkimizda-text19">
              <span>
                2012 yılından bu yana, başta Ankara ve İstanbul olmak üzere,
                Türkiye’nin farklı şehirlerinde pek çok özel ve kurumsal
                etkinlik düzenlemekteyiz. ELB Organizasyon olarak, alanında
                uzman şeflerimiz, deneyimli eğitmenlerimiz ve profesyonel
                ekibimizle, müşterilerimizin ihtiyaç ve beklentilerine yönelik
                yenilikçi çözümler sunmaktayız.
              </span>
              <br></br>
              <br></br>
              <span>
                Viski tadım etkinliklerinden, özenle planlanmış yemek
                organizasyonlarına; şık bahçe partilerinden geniş çaplı kurumsal
                etkinliklere kadar geniş bir hizmet yelpazesi sunarak, her
                etkinliği benzersiz kılmakta kararlıyız. Misafirlerimizin
                memnuniyeti, sunduğumuz hizmetin kalitesi ve etkinliklerimizin
                başarısı için temel önceliğimizdir.
              </span>
              <span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: ' ',
                  }}
                />
              </span>
            </span>
          </Fragment>
        }
      ></Features6>
      <ElbLogos
        heading1={
          <Fragment>
            <span className="hakkimizda-text25">ELB GRUP</span>
          </Fragment>
        }
        rootClassName="elb-logosroot-class-name1"
      ></ElbLogos>
      <Gallery9></Gallery9>
      <Footer
        link6={
          <Fragment>
            <span className="hakkimizda-text26">Anasayfa</span>
          </Fragment>
        }
        link7={
          <Fragment>
            <span className="hakkimizda-text27">Hakkımızda</span>
          </Fragment>
        }
        link8={
          <Fragment>
            <span className="hakkimizda-text28">Hizmetler</span>
          </Fragment>
        }
        link9={
          <Fragment>
            <span className="hakkimizda-text29">İletişim</span>
          </Fragment>
        }
        link10={
          <Fragment>
            <span className="hakkimizda-text30">Hemen Teklif Alın!</span>
          </Fragment>
        }
        copyright={
          <Fragment>
            <span className="hakkimizda-text31">
              © 2024 ELB Organizasyon. Tüm hakları saklıdır.
            </span>
          </Fragment>
        }
        column2Title={
          <Fragment>
            <span className="hakkimizda-text32">Site Haritası</span>
          </Fragment>
        }
        rootClassName="footerroot-class-name1"
      ></Footer>
    </div>
  )
}

export default Hakkimizda
