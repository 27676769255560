import React, { Fragment } from 'react'

import PropTypes from 'prop-types'

import './content-list13.css'

const ContentList13 = (props) => {
  return (
    <div className="content-list13-container thq-section-padding">
      <div className="content-list13-max-width thq-section-max-width">
        <div className="content-list13-content thq-flex-column">
          <ul className="content-list13-ul thq-flex-column">
            <li className="thq-flex-column list-item"></li>
            <li className="list-item">
              <h3 className="thq-heading-3">
                {props.heading2 ?? (
                  <Fragment>
                    <span className="content-list13-text2">Our Services</span>
                  </Fragment>
                )}
              </h3>
              <p className="thq-body-small">
                {props.content2 ?? (
                  <Fragment>
                    <span className="content-list13-text1">
                      We offer a wide range of services to meet all event needs,
                      including event planning, venue selection, catering,
                      entertainment, decorations, and more. Our team of
                      professionals is committed to delivering high-quality
                      services tailored to each client&apos;s unique
                      requirements.
                    </span>
                  </Fragment>
                )}
              </p>
            </li>
            <li className="list-item"></li>
            <li className="list-item"></li>
            <li className="list-item"></li>
            <li className="list-item"></li>
          </ul>
        </div>
      </div>
    </div>
  )
}

ContentList13.defaultProps = {
  content2: undefined,
  heading2: undefined,
}

ContentList13.propTypes = {
  content2: PropTypes.element,
  heading2: PropTypes.element,
}

export default ContentList13
