import React, { Fragment } from 'react'

import { Helmet } from 'react-helmet'

import Navbar from '../components/navbar'
import ContentList13 from '../components/content-list13'
import Footer from '../components/footer'
import './kvkk.css'

const Kvkk = (props) => {
  return (
    <div className="kvkk-container">
      <Helmet>
        <title>ELB Organizasyon</title>
        <meta
          name="description"
          content="ELB Organizasyon, İstanbul 4. Levent ve Beşiktaş’ta özel ve kurumsal etkinlikler sunan, catering ve tadım hizmetleriyle öne çıkan bir organizasyon şirketidir"
        />
        <meta property="og:title" content="ELB Organizasyon" />
        <meta
          property="og:description"
          content="ELB Organizasyon, İstanbul 4. Levent ve Beşiktaş’ta özel ve kurumsal etkinlikler sunan, catering ve tadım hizmetleriyle öne çıkan bir organizasyon şirketidir"
        />
        <meta
          property="og:image"
          content="https://aheioqhobo.cloudimg.io/v7/_playground-bucket-v2.teleporthq.io_/d8f84395-ef7e-45f7-bd5b-7c31cdc2ceb7/cb81269f-5301-494a-a792-4549cb492f4c?org_if_sml=1&amp;q=80&amp;force_format=original"
        />
      </Helmet>
      <Navbar
        link1={
          <Fragment>
            <span className="kvkk-text100">ANASAYFA</span>
          </Fragment>
        }
        link2={
          <Fragment>
            <span className="kvkk-text101">HAKKIMIZDA</span>
          </Fragment>
        }
        link3={
          <Fragment>
            <span className="kvkk-text102">HİZMETLER</span>
          </Fragment>
        }
        link4={
          <Fragment>
            <span className="kvkk-text103">İLETİŞİM</span>
          </Fragment>
        }
        action1={
          <Fragment>
            <span className="kvkk-text104">Hemen Arayın</span>
          </Fragment>
        }
        action2={
          <Fragment>
            <span className="kvkk-text105">Instagram</span>
          </Fragment>
        }
      ></Navbar>
      <ContentList13
        content2={
          <Fragment>
            <span className="kvkk-text106">
              <span>1. AMAÇ</span>
              <br></br>
              <br></br>
              <span>
                ELB Organizasyon olarak kişisel verilerinizin gizliliğine saygı
                duyuyor, veri güvenliğine büyük önem veriyoruz. Kişisel
                verilerin işlenmesi ve korunması hususu, şirketimizin en önemli
                öncelikleri arasındadır. Bu metin, müşterilerimizin, potansiyel
                müşterilerimizin, iş ortaklarımızın, çalışanlarımızın ve diğer
                ilgili kişilerin kişisel verilerinin 6698 sayılı Kişisel
                Verilerin Korunması Kanunu (KVKK) uyarınca toplanması, işlenmesi
                ve korunması süreçlerine dair bilgilendirme amacı taşımaktadır.
              </span>
              <br></br>
              <br></br>
              <span>
                ELB Organizasyon ile paylaşılan tüm kişisel veriler, KVKK
                kapsamında ve ilgili mevzuat çerçevesinde toplanmakta,
                işlenmekte ve saklanmaktadır. Verilerinizi hangi amaçlarla
                işlediğimizi, kimlerle paylaştığımızı ve sahip olduğunuz hakları
                anlamanız için bu metni dikkatle okumanızı öneririz.
              </span>
              <br></br>
              <br></br>
              <span>2. TANIMLAR</span>
              <br></br>
              <br></br>
              <span>
                Bu aydınlatma metninde geçen terimlerin tanımları şu şekildedir:
              </span>
              <br></br>
              <br></br>
              <span>
                • Kişisel Veri: Kimliği belirli veya belirlenebilir bir kişiye
                ait her türlü bilgi.
              </span>
              <br></br>
              <span>
                • Kişisel Verilerin İşlenmesi: Kişisel verilerin toplanması,
                kaydedilmesi, saklanması, değiştirilmesi, aktarılması gibi
                işlemleri.
              </span>
              <br></br>
              <span>
                • Veri Sorumlusu: Kişisel verilerin işlenme amaçlarını
                belirleyen, veri kayıt sistemini yöneten kişi veya kuruluş.
              </span>
              <br></br>
              <span>
                • Veri İşleyen: Veri sorumlusunun yetkisi dahilinde kişisel
                verileri işleyen gerçek veya tüzel kişi.
              </span>
              <br></br>
              <span>• İlgili Kişi: Kişisel verisi işlenen gerçek kişi.</span>
              <br></br>
              <br></br>
              <span>3. VERİ SORUMLUSU</span>
              <br></br>
              <br></br>
              <span>
                Veri Sorumlusu: ELB Organizasyon (Çağan Elbistanlıoğlu)
              </span>
              <br></br>
              <span>
                Adres: Esentepe Mah. Şehit Mehmet Mikdat Uluünlü sk. Propa Plus
                Residence No:26 İç Kapı 26 Şişli / İstanbul
              </span>
              <br></br>
              <span>E-posta: cagan@elborganizasyon.com</span>
              <br></br>
              <br></br>
              <span>4. UYGULAMA</span>
              <br></br>
              <br></br>
              <span>
                ELB Organizasyon, müşterilerimiz, çalışanlarımız, iş
                ortaklarımız ve ziyaretçilerimiz başta olmak üzere uhdemizde
                bulunan tüm kişisel verileri 6698 sayılı KVKK’ya uygun olarak
                toplamaktadır. Kişisel veriler, yalnızca faaliyetlerimizin
                gerektirdiği ölçüde işlenecek, ilgili mevzuata uygun şekilde
                saklanacak ve üçüncü kişilerle paylaşılabilecektir.
              </span>
              <br></br>
              <br></br>
              <span>5. KİŞİSEL VERİLERİNİZİN İŞLENME AMACI</span>
              <br></br>
              <br></br>
              <span>
                Kişisel verileriniz, KVKK’nın 4., 5. ve 6. maddeleri uyarınca
                işlenmektedir. Şirketimizin faaliyetlerini yürütmek amacıyla,
                meşru menfaatler çerçevesinde veya açık rızanızla şu amaçlar
                için işlenebilir:
              </span>
              <br></br>
              <br></br>
              <span>• Müşteri ilişkilerinin yönetimi</span>
              <br></br>
              <span>
                • İş ortakları ve tedarikçilerle iş süreçlerinin takibi
              </span>
              <br></br>
              <span>• Sözleşme süreçlerinin yönetimi</span>
              <br></br>
              <span>• Hukuki yükümlülüklerin yerine getirilmesi</span>
              <br></br>
              <span>• Finans ve muhasebe işlemlerinin yürütülmesi</span>
              <br></br>
              <br></br>
              <span>
                6. KİŞİSEL VERİLERİNİZİN TOPLANMA YÖNTEMİ VE HUKUKİ SEBEBİ
              </span>
              <br></br>
              <br></br>
              <span>
                Kişisel verileriniz, sözlü, yazılı veya elektronik yollarla,
                ticari süreçlerimiz kapsamında toplanmaktadır. Bu veriler,
                KVKK’nın ilgili maddeleri uyarınca meşru menfaatlerimiz
                gereğince veya açık rızanızla işlenmektedir.
              </span>
              <br></br>
              <br></br>
              <span>7. KİŞİSEL VERİLERİNİZİN AKTARILMASI</span>
              <br></br>
              <br></br>
              <span>
                Kişisel verileriniz, gerekli güvenlik önlemleri alınarak,
                Türkiye içindeki ve dışındaki iş ortaklarımıza,
                tedarikçilerimize ve yetkili kamu kurumlarına
                aktarılabilecektir.
              </span>
              <br></br>
              <br></br>
              <span>8. VERİLERİN SAKLANMASI VE SİLİNMESİ</span>
              <br></br>
              <br></br>
              <span>
                Kişisel verileriniz, mevzuatta belirtilen süreler boyunca
                saklanmakta ve gerekli süre sonunda silinmektedir. Saklanan
                veriler, fiziksel ve dijital ortamlarda güvence altına
                alınmaktadır.
              </span>
              <br></br>
              <br></br>
              <span>9. HAKLARINIZ</span>
              <br></br>
              <br></br>
              <span>KVKK kapsamında sahip olduğunuz haklar şunlardır:</span>
              <br></br>
              <br></br>
              <span>• Kişisel verilerinizin işlenip işlenmediğini öğrenme</span>
              <br></br>
              <span>
                • Kişisel verilerinizin düzeltilmesini, silinmesini veya yok
                edilmesini talep etme
              </span>
              <br></br>
              <span>• Kişisel verilerinizin işlenmesine itiraz etme</span>
              <br></br>
              <br></br>
              <span>
                Bu haklarınızı kullanmak için info@elborganizasyon.com üzerinden
                bizimle iletişime geçebilirsiniz.
              </span>
              <br></br>
              <br></br>
              <span>10. DİĞER HUSUSLAR</span>
              <br></br>
              <br></br>
              <span>
                Bu Aydınlatma Metni, kişisel verilerin işlenmesi ve korunmasıyla
                ilgili yürürlükteki mevzuata uyumlu olarak hazırlanmıştır.
                Güncel versiyon internet sitemizde yayımlanacaktır.
              </span>
            </span>
          </Fragment>
        }
        heading2={
          <Fragment>
            <span className="kvkk-text204">
              <span>KİŞİSEL VERİLERİN KORUNMASI KANUNU AYDINLATMA METNİ</span>
              <br></br>
            </span>
          </Fragment>
        }
      ></ContentList13>
      <Footer
        link6={
          <Fragment>
            <span className="kvkk-text207">Anasayfa</span>
          </Fragment>
        }
        link7={
          <Fragment>
            <span className="kvkk-text208">Hakkımızda</span>
          </Fragment>
        }
        link8={
          <Fragment>
            <span className="kvkk-text209">Hizmetler</span>
          </Fragment>
        }
        link9={
          <Fragment>
            <span className="kvkk-text210">İletişim</span>
          </Fragment>
        }
        link10={
          <Fragment>
            <span className="kvkk-text211">Hemen Teklif Alın!</span>
          </Fragment>
        }
        copyright={
          <Fragment>
            <span className="kvkk-text212">
              © 2024 ELB Organizasyon. Tüm hakları saklıdır.
            </span>
          </Fragment>
        }
        column2Title={
          <Fragment>
            <span className="kvkk-text213">Site Haritası</span>
          </Fragment>
        }
        rootClassName="footerroot-class-name6"
      ></Footer>
    </div>
  )
}

export default Kvkk
